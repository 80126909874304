import { Reducer } from 'redux';

import { SET_ERROR_MESSAGE, SET_SUCCESS_ALERT_MESSAGE } from 'actions/snackbar';

interface ISnackbarState {
  isAlertMessage: boolean,
  successMessage: string,
  isRequestError: boolean,
  errorMessage: string,
}

const getDefaultState = (): ISnackbarState => ({
  isAlertMessage: false,
  successMessage: '',
  isRequestError: false,
  errorMessage: '',
});

const snackbar: Reducer<ISnackbarState, any> = (stateVal, action) => {
  let state = stateVal;
  if (!state) state = getDefaultState();

  switch (action.type) {
    case SET_SUCCESS_ALERT_MESSAGE:
      return {
        ...state,
        successMessage: action.successMessage,
        isAlertMessage: action.isAlertMessage,
      };
    case SET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.errorMessage,
        isRequestError: action.isRequestError,
      };

    default:
      return state;
  }
};

export default snackbar;
