import { makeStyles, createStyles } from '@material-ui/core/styles';

const SearchStyle = makeStyles((theme) =>
  createStyles({
    searchWrapper: {
      borderRadius: '5px',
      display: 'flex',
      justifyContent: 'space-beetween',
      padding: '10px 20px',
      alignItems: 'flex-start',
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        flexFlow: 'column',
      },
    },
    resetWrapper: {
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'flex-start',
    },
    resetLink: {
      textAlign: 'right',
      margin: '0 0 5px 0',
      textDecoration: 'none',
      color: '#108ee9',
      fontSize: '14px',
    },
    searchBlockContainer: {
      textAlign: 'left',
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        flexFlow: 'column',
      },
    },
    calendarInput: {
      display: 'inline-flex',
      marginRight: '5px',
      marginBottom: '5px',
    },
    calendarWrapper: {
      boxSizing: 'border-box',
      padding: '10px 24px',
      flex: '0 0 20%',
      height: '39px',
      width: '195px',
      borderRadius: '4px',
      border: '1px solid rgba(0, 0, 0, 0.23)',
      color: 'rgba(0, 0, 0, 0.87)',
      [theme.breakpoints.down('xs')]: {
        margin: '0 0 5px 0',
        flex: '0 0 100%',
      },
    },
    searchInput: {
      flex: '0 0 32%',
      width: '200px',
      '&:first-of-type': {
        marginTop: '5px',
      },

      '& .MuiInputBase-formControl': {
        [theme.breakpoints.down('xs')]: {
          marginLeft: '0',
          marginBottom: '5px',
        },
      },
    },
    selectLabel: {
      '&.MuiInputLabel-root': {
        top: '-9px',
      },
      '&.MuiFormLabel-root.Mui-focused': {
        top: '0',
      },

      '&.MuiFormLabel-filled': {
        top: '0',
      },
    },
    selectProviderOptions: {
      '& .MuiOutlinedInput-input': {
        width: '120px',
        padding: '10px 50px 10px 10px',
      },
    },
    searchBlockButtons: {
      display: 'flex',
      alignItems: 'center',
      margin: '20px 0px',
      [theme.breakpoints.down('xs')]: {
        flexFlow: 'column',
      },
    },
    searchSubmit: {
      maxWidth: '100px',
      margin: '0 0 0 15px ',
    },
    saveReport: {
      width: '100px',
      margin: '0  0 0 15px',
      background: '#fff',
      color: '#000',
      border: '1px solid #207245',
      '& img': {
        maxWidth: '22px',
        maxHeight: '21px',
      },
      [theme.breakpoints.down('xs')]: {
        margin: '10px  auto 0 auto',
      },
    },
  })
);

export default SearchStyle;
