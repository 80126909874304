import React, { useState, useEffect, useCallback } from 'react';
import {
  Paper,
  Table,
  TableBody,
  Tooltip,
  TableCell,
  TableContainer,
  TableHead,
  TableFooter,
  TableRow,
  TablePagination,
  TableSortLabel,
  Button,
  Card,
  Typography,
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { withStyles } from '@material-ui/core/styles';
import PrintIcon from '@material-ui/icons/Print';
import IconButton from '@material-ui/core/IconButton';

import { splitDateString, formatMoney } from 'helpers/provider';
import Search from 'components/search/search';
import { printReceipt } from 'actions/api';
import EmptyContainer from 'components/emptyContainer';
import { useAppDispatch, useAppSelector } from 'hooks/reduxTypedHooks';

import useStyles from './reportStyle';
import {
  REPORT_PROCESSING_ERROR_STATUS_CODE,
  REPORT_PROCESSING_STATUSES,
  REPORT_STATUSES,
} from 'constants/search';

const StyledTableCell = withStyles((theme) => {
  return {
    head: {
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.common.black,
    },
    body: {
      fontSize: 14,
    },
  };
})(TableCell) as any;

type TOrder = 'asc' | 'desc';

const Report = () => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [temporaryPageForNav, setTemporaryPageForNav] = useState<
    string | number
  >(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [providerListState, setProviderListState] = useState<any[]>([]);
  const [order, setOrder] = useState<TOrder>('asc');
  const [orderBy, setOrderBy] = useState('');

  const dispatch = useAppDispatch();
  const { providerList } = useAppSelector((state) => state.provider);

  useEffect(() => {
    setPage(0);
    if (providerList?.data) {
      setProviderListState(providerList.data);
    }
    if (providerList.data && providerList.data.length > 0) {
      setOrderBy('date');
    } else {
      setProviderListState([]);
    }
  }, [providerList]);

  interface IObj {
    [key: string]: any;
  }

  const getSorting = (sortDirection: string, fieldForSortBy: string) =>
    sortDirection === 'desc'
      ? (a: IObj, b: IObj) => (a[fieldForSortBy] < b[fieldForSortBy] ? -1 : 1)
      : (a: IObj, b: IObj) => (b[fieldForSortBy] < a[fieldForSortBy] ? -1 : 1);

  const createSortHandler = (key: string) => {
    setOrderBy(key);
    setOrder(order === 'asc' ? 'desc' : 'asc');
  };

  const handleChangePage = (_: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const setPageForPaginationNav = () => {
    const requestedPage = Number(temporaryPageForNav);

    if (
      requestedPage &&
      providerListState.length / rowsPerPage > requestedPage - 1
    ) {
      setPage(requestedPage - 1);
    }
  };

  const getOrderForRow = (orderField: string) => {
    if (orderField === orderBy) return order === 'asc' ? 'desc' : 'asc';
    return 'asc';
  };

  const getSortedArr = (list: any[]) => {
    return list.sort(getSorting(order, orderBy));
  };

  const getBgColorForReportStatus = (processingStatus: number) => {
    switch (processingStatus) {
      case 0:
        return classes.blueStatus;
      case 1:
        return classes.greenStatus;
      case 2:
        return classes.redStatus;
      default:
        return classes.grayStatus;
    }
  };

  const getReportStatusText = (item: any) => {
    let statusValue = '';
    if (!item.postStatus) {
      if (
        item.eCode &&
        item.processingStatus === REPORT_PROCESSING_ERROR_STATUS_CODE
      ) {
        statusValue = item.eName;
      } else if (
        item.processingStatus !== REPORT_PROCESSING_ERROR_STATUS_CODE
      ) {
        statusValue = REPORT_PROCESSING_STATUSES[item.processingStatus];
      }
    } else {
      statusValue = REPORT_STATUSES.filter(
        (status) => status.code === item.postStatus
      )[0].label;
    }

    return statusValue;
  };

  const getKey = useCallback((date: number, index: number) => {
    return date + index;
  }, []);

  return (
    <div className={classes.container}>
      <Typography variant="h5" style={{ marginBottom: 30 }}>
        Поиск платежей
      </Typography>
      <Card className={classes.card}>
        <Search setOrderBy={setOrderBy} />
      </Card>

      <div className={classes.providerTableWrapper}>
        {providerListState && providerListState.length ? (
          <Card className={classes.card}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow className={(classes as any).rowWrapper}>
                    <StyledTableCell className={classes.tableTD}>
                      №
                    </StyledTableCell>
                    <StyledTableCell className={classes.tableTD} align="center">
                      <TableSortLabel
                        active={orderBy === 'idService'}
                        direction={getOrderForRow('idService')}
                        onClick={() => createSortHandler('idService')}
                        className={classes.highlightRow}
                        aria-label="sortByIdService"
                      >
                        ID сервиса
                      </TableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell className={classes.tableTD} align="center">
                      <TableSortLabel
                        active={orderBy === 'serviceName'}
                        className={classes.highlightRow}
                        direction={getOrderForRow('serviceName')}
                        onClick={() => createSortHandler('serviceName')}
                        aria-label="sortByServiceName"
                      >
                        Сервис
                      </TableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell className={classes.tableTD} align="center">
                      <TableSortLabel
                        active={orderBy === 'date'}
                        className={classes.highlightRow}
                        direction={getOrderForRow('date')}
                        onClick={() => createSortHandler('date')}
                        aria-label="sortByDate"
                      >
                        Дата платежа
                      </TableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell className={classes.tableTD} align="center">
                      <TableSortLabel
                        active={orderBy === 'amount'}
                        className={classes.highlightRow}
                        direction={getOrderForRow('amount')}
                        onClick={() => createSortHandler('amount')}
                        aria-label="sortByAmount"
                      >
                        Зачислено на счет
                      </TableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell className={classes.tableTD} align="center">
                      <TableSortLabel
                        active={orderBy === 'sn'}
                        className={classes.highlightRow}
                        direction={getOrderForRow('sn')}
                        onClick={() => createSortHandler('sn')}
                        aria-label="sortBySn"
                      >
                        Номер транзакции
                      </TableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell className={classes.tableTD} align="center">
                      <TableSortLabel
                        className={classes.highlightRow}
                        onClick={() => createSortHandler('provSn')}
                        aria-label="sortByProvSn"
                      >
                        Референс провайдера
                      </TableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell className={classes.tableTD} align="center">
                      <TableSortLabel
                        active={orderBy === 'account'}
                        className={classes.highlightRow}
                        direction={getOrderForRow('account')}
                        onClick={() => createSortHandler('account')}
                        aria-label="sortByAccount"
                      >
                        Лицевой счет
                      </TableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell className={classes.tableTD} align="center">
                      <TableSortLabel
                        className={classes.highlightRow}
                        onClick={() => createSortHandler('serviceAmount')}
                        aria-label="sortByServiceAmount"
                      >
                        Вознаграждение
                      </TableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell className={classes.tableTD} align="center">
                      <TableSortLabel
                        className={classes.highlightRow}
                        onClick={() => createSortHandler('remuneration')}
                        aria-label="sortByCommission"
                      >
                        Комиссия
                      </TableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell className={classes.tableTD} align="center">
                      <TableSortLabel className={classes.highlightRow}>
                        Статус
                      </TableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell className={classes.tableTD} align="center">
                      <TableSortLabel
                        className={classes.highlightRow}
                        onClick={() => createSortHandler('account')}
                        aria-label="sortByReceipt"
                      >
                        Чек
                      </TableSortLabel>
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody aria-label="tableBody">
                  {providerListState &&
                    getSortedArr(providerListState)
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item, idx) => {
                        return (
                          <TableRow
                            key={getKey(item.date, idx)}
                            className={(classes as any).rowWrapper}
                          >
                            <TableCell className={classes.tableTD}>
                              {page * rowsPerPage + idx + 1}
                            </TableCell>
                            <TableCell
                              className={classes.tableTD}
                              align="center"
                            >
                              {item.idService}
                            </TableCell>
                            <TableCell
                              className={classes.tableTD}
                              align="center"
                            >
                              {item.serviceName}
                            </TableCell>
                            <TableCell
                              className={classes.tableTD}
                              align="center"
                            >
                              {splitDateString(item.date)}
                            </TableCell>
                            <TableCell
                              className={classes.tableTD}
                              align="center"
                            >
                              {formatMoney(item.amount)} ₸
                            </TableCell>
                            <TableCell
                              className={classes.tableTD}
                              align="center"
                            >
                              {item.sn}
                            </TableCell>
                            <TableCell
                              className={classes.tableTD}
                              align="center"
                            >
                              {item?.provSn ?? '-'}
                            </TableCell>
                            <TableCell
                              className={classes.tableTD}
                              align="center"
                            >
                              {item.account}
                            </TableCell>
                            <TableCell
                              className={classes.tableTD}
                              align="center"
                            >
                              {Number(item.serviceAmount).toLocaleString()} ₸
                            </TableCell>
                            <TableCell
                              className={classes.tableTD}
                              align="center"
                            >
                              {Number(item.remuneration).toLocaleString()} ₸
                            </TableCell>
                            <TableCell
                              className={classes.tableTD}
                              align="center"
                            >
                              <div
                                className={[
                                  classes.statusText,
                                  getBgColorForReportStatus(
                                    item.processingStatus
                                  ),
                                ].join(' ')}
                              >
                                {getReportStatusText(item)}
                              </div>
                            </TableCell>

                            <TableCell
                              className={classes.tableTD}
                              align="center"
                            >
                              <Tooltip title="Печать">
                                <IconButton
                                  color="primary"
                                  aria-label="upload picture"
                                  component="span"
                                  onClick={() =>
                                    dispatch(
                                      printReceipt(item.idTerminal, item.sn)
                                    )
                                  }
                                >
                                  <PrintIcon />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell align="center" colSpan={6}>
                      {/* @ts-ignore */}
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={
                          (providerListState && providerListState.length) || 0
                        }
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        labelRowsPerPage={'Количество на странице'}
                        labelDisplayedRows={({ from, to, count }) => {
                          return `${from}-${to} из ${count}`;
                        }}
                        aria-label="tabelPaging"
                      />
                    </TableCell>
                    {providerList &&
                      providerList?.result?.totalCount > rowsPerPage && (
                        <TableCell align="center">
                          <input
                            onChange={(e) =>
                              setTemporaryPageForNav(e.target.value)
                            }
                            placeholder="перейти к странице"
                            className={classes.paginationInput}
                          />
                          <Button onClick={setPageForPaginationNav}>
                            перейти <ArrowForwardIcon />
                          </Button>
                        </TableCell>
                      )}
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>

            <div style={{ paddingBottom: 20 }}>
              <>
                <div className={classes.infoContainer}>
                  <span className={classes.infoBlock}>
                    Количество платежей:
                  </span>
                  <span
                    className={classes.infoBlock}
                    aria-label="paymentsCount"
                  >
                    {providerList?.result && providerList?.result.totalCount}
                  </span>
                </div>
                <div className={classes.infoContainer}>
                  <span className={classes.infoBlock}>
                    Итого зачислено на счет:
                  </span>
                  <span
                    className={classes.infoBlock}
                    aria-label="paymentsTotal"
                  >
                    {providerList?.result &&
                      `${formatMoney(providerList.result.totalAmount)} ₸`}
                  </span>
                </div>
              </>
            </div>
          </Card>
        ) : (
          <Card className={classes.card}>
            <EmptyContainer />
          </Card>
        )}
      </div>
    </div>
  );
};

export default Report;
