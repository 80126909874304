import React, { useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';

import { getFromLocalStorage } from 'helpers/provider';
import { redirectToPage } from 'actions/navigation';
import Header from 'components/layout/header/header';
import Footer from 'components/layout/footer/footer';
import Snackbar from 'components/snackbar/Snackbar';
import { useAppSelector, useAppDispatch } from 'hooks/reduxTypedHooks';

import useStyles from './layoutStyle';

interface IProps {
  children: React.ReactNode,
  path: string,
}

const Layout: React.FC<IProps> = (props) => {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.provider);

  const classes = useStyles();
  const { path } = props;
  const authData = getFromLocalStorage('authUserData');
  const isAuth = authData !== null;

  useEffect(() => {
    if (!isAuth && path !== '/' && path !== '/sendpassword') {
      dispatch(redirectToPage(''));
    }
    if (isAuth && path === '/') {
      dispatch(redirectToPage('report'));
    }

  }, [path,isAuth]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={
        path === '/' || path === '/sendpassword'
          ? classes.layoutAuthWrapper
          : classes.layoutWrapper
      }
      data-testid="layoutMain"
    >
      {isLoading && (
        <div className={classes.loaderWrapper} data-testid="loaderWrapper">
          <CircularProgress className={classes.loaderContainer} />
        </div>
      )}
      {path !== '/' && path !== '/sendpassword' && <Header />}
      <div
        className={classes.layoutChildrenWrapper}
        style={{ minHeight: 'calc(100vh - 111px)' }}
      >
        {props.children}
      </div>
      <Snackbar />
      {path !== '/' && path !== '/sendpassword' && <Footer />}
    </div>
  );
};

export default Layout;
