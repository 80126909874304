import React from 'react';
import {
  Dialog,
  DialogContent,
  IconButton,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';
import ClosedIcon from '@material-ui/icons/Close';

interface IProps {
  open: boolean,
  scroll?: string,
  onClose: () => void,
  dividers?: boolean,
  maxWidth?: false | 'sm' | 'md' | 'xs' | 'lg' | 'xl',
  dialogTitle?: string,
  dialogActions?: React.ReactElement | null,
  children?: React.ReactNode,
}

const Modal: React.FC<IProps> = (props) => {
  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth={props.maxWidth}>
      {props.dialogTitle ? (
        <DialogTitle aria-label="modalHead">{props.dialogTitle}</DialogTitle>
      ) : (
        ''
      )}

      <IconButton
        color="secondary"
        onClick={props.onClose}
        style={{ position: 'absolute', right: '0' }}
        data-testid="closeModalButton"
      >
        <ClosedIcon />
      </IconButton>
      <DialogContent dividers={props.dividers}>{props.children}</DialogContent>
      {props.dialogActions ? (
        <DialogActions aria-label="modalActions">
          {props.dialogActions}
        </DialogActions>
      ) : (
        ''
      )}
    </Dialog>
  );
};

Modal.defaultProps = {
  maxWidth: 'sm',
};

export default Modal;
