import React, { useState, useEffect } from 'react';
import Modal from 'components/modal';
import {
  Table,
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
  Checkbox,
  TextField,
  Button,
  TableHead,
} from '@material-ui/core';

import { sortData } from 'helpers/provider';
import { TExportType, TParamsColumns } from 'types/params';

import useStyles from './modalStyle';

const EXPORT_FIELDS = [
  { label: 'ID сервиса', name: 'idService' },
  { label: 'ID провайдера', name: 'idProvider' },
  { label: 'Дата платежа', name: 'date' },
  { label: 'Зачислено на счет', name: 'amount' },
  { label: 'Номер транзакции', name: 'sn' },
  { label: 'Референс провайдера', name: 'provSn' },
  { label: 'Сервис', name: 'serviceName' },
  { label: 'Лицевой счет', name: 'account' },
  { label: 'Вознаграждение', name: 'serviceAmount' },
  { label: 'Комиссия', name: 'remuneration' },
  { label: 'Статус', name: 'StatusDescriptions' },
] as const;

const TABLE_HEAD = [
  { label: 'Выгружать в файл', width: '15%', id: 'exportFile' },
  { label: 'Название поля (по-умолчанию)', width: '32%', id: 'name' },
  { label: 'Новое название поля', width: '32%', id: 'newName' },
  { label: 'Порядок', width: '20%', id: 'sort' },
];

interface IProps {
  downloadReport: (type: TExportType, columns?: TParamsColumns) => void;
  exportType: TExportType;
  onClose: () => void;
}

const ExportModal: React.FC<IProps> = (props) => {
  const { downloadReport, exportType, onClose } = props;
  const classes = useStyles();

  interface ITempParams {
    [key: string]: any;
  }

  const [propertyParams, setPropertyParams] = useState<ITempParams>({});

  useEffect(() => {
    let tempParams: ITempParams = {};
    EXPORT_FIELDS.forEach((field, i) => {
      tempParams[`${field.name}_check`] = false;
      tempParams[field.name] = field.label;
      tempParams[`${field.name}_sort`] = 0;
    });
    setPropertyParams(tempParams);
  }, []);

  interface IData {
    name: string;
    order: number;
    title?: string;
  }

  const handlerDownloadReport = (type: TExportType) => {
    let totalData: IData[] = [];

    EXPORT_FIELDS.forEach((field) => {
      let data: IData = { name: '', order: 0 };
      if (propertyParams[`${field.name}_check`]) {
        data.name = field.name;
        data.title = propertyParams[field.name];
        data.order = Number(propertyParams[`${field.name}_sort`]);
        totalData.push(data);
      }
    });
    if (totalData.length > 0) {
      const sortedData = sortData(totalData, 'order');
      downloadReport(type, sortedData);
    } else {
      downloadReport(type);
    }
  };

  const propertyHandleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: string = ''
  ) => {
    let tempParams = { ...propertyParams };
    tempParams = {
      ...tempParams,
      [e.target.name]:
        e.target.name.indexOf('_check') !== -1
          ? e.target.checked
          : e.target.value,
    };

    if (e.target.checked === true) {
      let checked = 1;
      EXPORT_FIELDS.forEach((item) => {
        if (propertyParams[`${item.name}_check`]) checked++;
      });
      tempParams[field + '_sort'] = checked;
    }

    setPropertyParams(tempParams);
  };

  return (
    <>
      <Modal
        dialogTitle={`Экспорт в ${exportType}`}
        onClose={onClose}
        open={true}
        maxWidth="md"
        dialogActions={
          <Button
            onClick={() => handlerDownloadReport(exportType)}
            variant="contained"
            color="primary"
            className={classes.searchSubmit}
          >
            Экспорт
          </Button>
        }
      >
        <TableContainer>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {TABLE_HEAD.map((item) => {
                  return (
                    <TableCell key={item.id} width={item.width} align="center">
                      {item.label}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {EXPORT_FIELDS.map((field) => {
                return (
                  <TableRow
                    key={field.name}
                    className={
                      !propertyParams[`${field.name}_check`]
                        ? classes.checked
                        : ''
                    }
                  >
                    <TableCell>
                      <Checkbox
                        name={`${field.name}_check`}
                        color="primary"
                        onChange={(e) => propertyHandleChange(e, field.name)}
                        value="check"
                        inputProps={{ 'aria-label': `${field.name}_check` }}
                        aria-label={`${field.name}_check`}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        variant="outlined"
                        disabled
                        value={field.label}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        variant="outlined"
                        name={field.name}
                        defaultValue={field.label}
                        size="small"
                        onChange={(e) =>
                          propertyHandleChange(
                            e as React.ChangeEvent<HTMLInputElement>
                          )
                        }
                        disabled={!propertyParams[`${field.name}_check`]}
                        inputProps={{
                          'aria-label': field.name,
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        variant="outlined"
                        type="number"
                        name={`${field.name}_sort`}
                        size="small"
                        onChange={(e) =>
                          propertyHandleChange(
                            e as React.ChangeEvent<HTMLInputElement>
                          )
                        }
                        disabled={!propertyParams[`${field.name}_check`]}
                        value={propertyParams[`${field.name}_sort`] || 0}
                        inputProps={{
                          'aria-label': `${field.name}_sort`,
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Modal>
    </>
  );
};

export default ExportModal;
