import ExcelIcon from 'assets/img/excel.png';

export const EXPORT_REPORT_TYPES = [
  { label: 'xlsx', image: ExcelIcon },
  { label: 'csv', image: ExcelIcon },
] as const;

export const REPORT_STATUSES = [
  { code: '1', label: 'Помечен на отмену' },
  { code: '2', label: 'В процессе отмены' },
  { code: '3', label: 'Отменен' },
  { code: '4', label: 'Отмена отклонена' },
  { code: '5', label: 'Перепроведен' },
  { code: 'a', label: 'Отменен вручную' },
] as const;
export const REPORT_PROCESSING_SUCCESS_STATUS_CODE = 1;
export const REPORT_PROCESSING_ERROR_STATUS_CODE = 2;
export const REPORT_PROCESSING_STATUSES = ['В обработке', 'Проведен', 'Ошибка'];
