import { AppDispatch } from 'types/redux';
import { api } from 'instance/axiosIntanse';
import { redirectToPage } from 'actions/navigation';
import { setRequestError, setSuccessMessage } from 'actions/snackbar';
import {
  isSuccessStatusCode,
  removeItemFromLocalStarage,
} from 'helpers/provider';
import {
  setCurrentUserProfileProvider,
  setIsRequestedServiceList,
  setServiceList,
} from 'actions/provider';

export const SET_PROVIDER_PROFILE_INFO = 'SET_PROVIDER_PROFILE_INFO';

export const setProviderProfileInfo =
  (data: any) => (dispatch: AppDispatch) => {
    dispatch({ type: SET_PROVIDER_PROFILE_INFO, data });
  };

export const getUserConfig = () => {
  return api.post(`/user/getconfigs`).then((res) => {
    return res;
  });
};

export const setUserConfig = (period: string) => (dispatch: AppDispatch) => {
  return api
    .post(`/user/setconfigs`, {
      params: {
        'system.pswRotationDayCount': period,
      },
    })
    .then((res) => {
      const { data, status } = res;
      if (isSuccessStatusCode(status)) {
        if (data.code === 0) {
          dispatch(
            setSuccessMessage('Частота смены пароля успешно сохранена', true)
          );
        }
      } else {
        dispatch(setRequestError(data, true));
      }
      return res;
    });
};

export const getUserProfileByProvider =
  (idProvider: number) => (dispatch: AppDispatch) => {
    return api
      .post(`/provider/profile`, {
        params: {
          idProvider: idProvider,
        },
      })
      .then((res) => {
        if (res.data.code === 0)
          dispatch(setProviderProfileInfo(res.data.result));
        else dispatch(setProviderProfileInfo({}));
        return res;
      });
  };

export const clearUserParams = () => (dispatch: AppDispatch) => {
  removeItemFromLocalStarage('authUserData');
  removeItemFromLocalStarage('currentUser');
  removeItemFromLocalStarage('currentProvider');
  dispatch(setIsRequestedServiceList(false));
  dispatch(setCurrentUserProfileProvider({}));
  dispatch(setServiceList([]));
  dispatch(redirectToPage(''));
};
