import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import {
  AppBar,
  Toolbar,
  Button,
  Grid,
  MenuItem,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import {
  ExpandMoreSharp,
  ExpandLessSharp,
  AccountCircleOutlined,
  Check,
  Settings,
} from '@material-ui/icons';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { getServiceList, logoutRequest } from 'actions/api';
import { redirectToPage } from 'actions/navigation';
import { setCurrentUserProfileProvider } from 'actions/provider';
import { getFromLocalStorage, setToLocalStorage } from 'helpers/provider';
import LogoKassa24 from 'assets/img/kassa24-black.svg';
import {
  HEADER_MENU,
  PERSONAL_MENU,
  SERVICE_LIST_SUCCESS_CODE,
} from 'constants/main';
import { useAppSelector, useThunkAppDispatch } from 'hooks/reduxTypedHooks';

import useStyles from './headerStyle';
import useSearchStyles from 'components/search/searchStyle';
import { TServiceList } from 'types/provider';

const Header = () => {
  const dispatch = useThunkAppDispatch();
  const currentProvider = getFromLocalStorage('currentProvider');
  const [currentUserProvider, setCurrentUserProvider] = useState(
    currentProvider?.name
  );
  const [openDrawer, setOpenDrawer] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = { ...useStyles(), ...useSearchStyles() };
  const path = useLocation().pathname;
  const logOut = () => {
    dispatch(logoutRequest());
  };
  const currentUser = getFromLocalStorage('currentUser');
  const authData = getFromLocalStorage('authUserData');

  const { serviceList, isRequestedServiceList } = useAppSelector(
    (state) => state.provider
  );

  useEffect(() => {
    if (currentProvider) {
      dispatch(setCurrentUserProfileProvider(currentProvider));
    }

    if (serviceList.length > 0 && !currentProvider) {
      handleProfileMenuItemClick(serviceList[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, serviceList]);

  useEffect(
    () => {
      if (isRequestedServiceList && currentUser) {
        dispatch(getServiceList());
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser]
  );

  useEffect(
    () => {
      if (!isRequestedServiceList) {
        dispatch(getServiceList()).then((res) => {
          if (res.data.Code === SERVICE_LIST_SUCCESS_CODE && !currentProvider) {
            handleProfileMenuItemClick(res.data.QueryData[0].Rows[0]);
          }
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isRequestedServiceList]
  );

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorEl((event as { currentTarget: any }).currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenDrawer(false);
  };

  const handleMenuItemClick = (link: string) => {
    dispatch(redirectToPage(link));
    handleClose();
  };

  const handleProfileMenuItemClick = (
    profile: TServiceList[number] | '' = ''
  ) => {
    setToLocalStorage('currentProvider', profile);
    setCurrentUserProvider((profile as TServiceList[number]).name);
    dispatch(setCurrentUserProfileProvider(profile));
    handleClose();
  };

  const getCurrenUserInfo = () => {
    return (
      <>
        <Grid
          container
          spacing={2}
          className={classes.menuContent}
          alignItems="center"
        >
          <Grid item xs={3}>
            <AccountCircleOutlined style={{ fontSize: 36 }} color="disabled" />
          </Grid>
          <Grid item xs={9}>
            <div>{currentUser}</div>
            <Link
              to="settings"
              className={classes.resetLink}
              onClick={handleClose}
            >
              Настройки
            </Link>
          </Grid>
        </Grid>
        <Divider />
      </>
    );
  };

  const getMenuProfiles = (device = '') => {
    return (
      <>
        <div className={classes.menuProfilesList}>
          {serviceList.map((item) => {
            return (
              <MenuItem
                key={item.id}
                onClick={() => {
                  handleProfileMenuItemClick(item);
                }}
              >
                {item.id === currentProvider?.id && (
                  <div className={classes.providerCheckIcon}>
                    <Check
                      style={{ fontSize: device === 'mobile' ? 24 : 14 }}
                    />
                  </div>
                )}
                {item.name}
              </MenuItem>
            );
          })}
        </div>
        <Divider />
      </>
    );
  };

  const getHeaderMainMenu = () => {
    let mainMenu: JSX.Element[] = [];

    if (path !== '/' && authData !== null) {
      HEADER_MENU.forEach((menu, key) => {
        mainMenu.push(
          <Link
            key={menu.link}
            to={path !== menu.link ? menu.link : '#'}
            className={
              path === menu.link
                ? `${classes.headMainMenuItem} ${classes.headMainMenuItemActive} `
                : classes.headMainMenuItem
            }
          >
            {menu.name}
          </Link>
        );
      });
    }
    return mainMenu;
  };

  const getHeaderMainMenuMobile = () => {
    let mainMenu: JSX.Element[] = [];

    if (path !== '/' && authData !== null) {
      HEADER_MENU.forEach(({ name, link, Icon }, key) => {
        mainMenu.push(
          <ListItem
            button
            onClick={() => {
              handleMenuItemClick(link);
            }}
            key={link}
          >
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            <ListItemText primary={name} className={classes.headerLink} />
          </ListItem>
        );
      });
    }
    return mainMenu;
  };

  const getButtonIcon = useCallback(() => {
    if (Boolean(anchorEl)) {
      return <ExpandLessSharp />;
    }
    return <ExpandMoreSharp />;
  }, [anchorEl]);

  return (
    <AppBar position="static">
      <Toolbar className={classes.headerWrapper}>
        {path !== '/' && path !== '/sendpassword' && (
          <Button
            onClick={() => setOpenDrawer(true)}
            className={classes.burgerButton}
            aria-label="burgerButton"
          >
            <MenuIcon className={classes.menuIcon} />
          </Button>
        )}
        <div className={classes.logoWrapper}>
          <img src={LogoKassa24} alt="kassa24" className={classes.headerLogo} />
        </div>
        <div className={classes.headerMenu}>{getHeaderMainMenu()}</div>
        {path !== '/' && path !== '/sendpassword' && authData !== null ? (
          <>
            <div className={classes.menuWrapper}>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                className={classes.chipWrapper}
                endIcon={getButtonIcon()}
              >
                <div className={classes.userProfileInfo}>
                  <div className={classes.userLogin}>{currentUser}</div>
                  <div className={classes.profileProvider}>
                    {currentUserProvider}
                  </div>
                </div>
              </Button>
              <Popover
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                // className={classes.menuItem}
                data-testid="simple-menu"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                {getCurrenUserInfo()}
                {getMenuProfiles()}
                <div className={classes.menuProfilesList}>
                  {PERSONAL_MENU.map((menu, key) => {
                    return (
                      <MenuItem
                        key={menu.link}
                        onClick={() => {
                          handleMenuItemClick(menu.link);
                        }}
                      >
                        {menu.title}
                      </MenuItem>
                    );
                  })}
                  <MenuItem onClick={() => logOut()}> Выйти</MenuItem>
                </div>
              </Popover>
            </div>
            <Drawer
              anchor={'left'}
              open={openDrawer}
              onClose={() => {
                setOpenDrawer(false);
              }}
              data-testid="menuDrawer"
            >
              <div className={classes.chipWrapper} />

              <List>
                <ListItem button>
                  <ListItemIcon>
                    <img
                      src={LogoKassa24}
                      alt="kassa24"
                      className={classes.headerLogo}
                    />
                  </ListItemIcon>
                </ListItem>
              </List>
              <Divider />
              <div className={classes.mobileDrawerContent}>
                {getCurrenUserInfo()}
                {getMenuProfiles('mobile')}
              </div>
              <List>
                {PERSONAL_MENU.map((menu, key) => {
                  return (
                    <ListItem
                      button
                      onClick={() => {
                        handleMenuItemClick(menu.link);
                      }}
                      key={menu.link}
                    >
                      <ListItemIcon>
                        <Settings />
                      </ListItemIcon>
                      <ListItemText
                        primary={menu.title}
                        className={classes.headerLink}
                      />
                    </ListItem>
                  );
                })}
                {getHeaderMainMenuMobile()}
                <ListItem
                  button
                  onClick={() => logOut()}
                  data-testid="logoutButton"
                >
                  <ListItemIcon>
                    <ExitToAppIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={'Выйти'}
                    className={classes.headerLink}
                  />
                </ListItem>
              </List>
            </Drawer>
          </>
        ) : (
          <div />
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
