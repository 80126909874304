import React from 'react';
import { Typography, Card } from '@material-ui/core';

import { PROV_EMAIL } from 'constants/main';

import useStyles from './FaqStyle';

const Instruction = () => {
  const classes = useStyles();

  return (
    <Card
      className={[classes.card, classes.autoHeight, classes.marginTop].join(
        ' '
      )}
    >
      <Typography variant="overline">Инструкция</Typography>
      <p>
        <a
          target="_blank"
          href="https://docs.kassa24.kz/books/protokol-vzaimodeistviya-s-provaiderami-uslug/page/dokumentaciya"
          rel="noopener noreferrer"
        >
          Ссылка
        </a>{' '}
        на документацию технического взаимодействия
      </p>
      Для добавления дополнительной новой услуги или технической доработки
      обращаться на почту <a href={`mailto:${PROV_EMAIL}`}>{PROV_EMAIL}</a>
    </Card>
  );
};
export default Instruction;
