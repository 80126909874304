import { Reducer } from 'redux';

import { SET_PROVIDER_PROFILE_INFO } from 'actions/user';
import { ICurrentUserProfileProvider } from 'types/provider';

interface IUsersState {
  providerProfileInfo: ICurrentUserProfileProvider;
}

const getDefaultState = (): IUsersState => ({
  providerProfileInfo: {} as ICurrentUserProfileProvider,
});

const users: Reducer<IUsersState, any> = (stateVal, action) => {
  let state = stateVal;
  if (!state) state = getDefaultState();

  if (action.type === SET_PROVIDER_PROFILE_INFO) {
    return {
      ...state,
      providerProfileInfo: action.data,
    };
  }
  return state;
};

export default users;
