import axios, { AxiosRequestConfig } from 'axios';
import { getFromLocalStorage } from 'helpers/provider';
import { logoutRequest, sendRefreshToken } from 'actions/api';
import { startLoader, stopLoader } from 'actions/provider';
import { API_URL } from 'constants/api';
import { setRequestError } from 'actions/snackbar';
import { logErrorToSentry } from 'helpers/sentry';
import { AppDispatch, TStore } from 'types/redux';
import { clearUserParams } from 'actions/user';

let counter = 0;

export const api = axios.create({
  baseURL: API_URL,
});

export const makeInterceptorRequestUseConfig = (
  dispatch: AppDispatch,
  config: AxiosRequestConfig
) => {
  const authData = getFromLocalStorage('authUserData');

  if (authData) {
    config.headers.Authorization = `Bearer ${authData.token}`;
    config.headers.Accept = 'application/json';
  }
  config.headers['X-Client-App'] = 'prov';

  if ((config as any).format === 'xlsx') {
    config.responseType = 'blob';
  }

  if (config.url !== '/account/captcha') {
    dispatch(startLoader());
  }
  return config;
};

export const interceptorInstance = (store: ReturnType<TStore>) => {
  const { dispatch } = store;

  api.interceptors.request.use((config) => {
    return makeInterceptorRequestUseConfig(dispatch, config);
  });

  api.interceptors.response.use(
    (res) => {
      dispatch(stopLoader());
      return res;
    },
    (err) => {
      dispatch(stopLoader());
      const authData = getFromLocalStorage('authUserData');
      const originalRequest = err.config;

      if (err.response) {
        if (err.response.status === 401 && counter === 0) {
          counter = 1;

          if (authData && authData.token) {
            dispatch(sendRefreshToken());
            originalRequest.headers.Authorization = `Bearer ${authData.token}`;
            return api.request(originalRequest);
          }
        }

        counter = checkErrorResponseCode(err, dispatch);
      }

      return Promise.reject(err);
    }
  );
};

const checkErrorResponseCode = (err: any, dispatch: AppDispatch) => {
  const authData = getFromLocalStorage('authUserData');

  if (err.response?.status === 401 && counter === 1) {
    if (authData) {
      dispatch(logoutRequest());
    }
    dispatch(clearUserParams());

    counter = 0;
  }

  if (err.response?.status === 403) {
    dispatch(
      setRequestError(
        'У пользователя нет прав для выполнения данной операции',
        true
      )
    );
  }

  if (err.response?.status >= 500) {
    logErrorToSentry(err);
  }

  return 0;
};
