import { makeStyles, createStyles } from '@material-ui/core/styles';

const reportStyle = makeStyles((theme) =>
  createStyles({
    container: {
      margin: '30px 40px',
      borderRadius: '5px',
      padding: '20px',
      [theme.breakpoints.down('xs')]: {
        margin: '10px',
        padding: '0',
      },
    },
    goToPageInput: {
      width: '15px',
    },

    providerListAlert: {
      padding: '10px',
      width: '70%',
      borderRadius: '5px',
      background: 'rgb(255, 244, 229)',
      margin: '10px auto',
    },
    highlightRow: {
      '&.MuiTableSortLabel-active': {
        fontSize: '15px',
        color: 'rgb(210 200 56)',
      },
    },

    infoBlock: {
      marginLeft: '10px',
    },
    infoContainer: {
      display: 'flex',
      fontSize: '13px',
      '&:first-of-type': {
        marginTop: '15px',
      },
    },

    providerTableWrapper: {
      marginTop: 10,
    },
    tableTD: {
      '&.MuiTableCell-root': {
        padding: '8px',
        border: 'none',
        borderBottom: '1px solid #EBEBEB',
      },
    },
    menuItem: {
      top: 25,
    },
    excelIcon: {
      width: '20px',
    },
    warningBlock: {
      textAlign: 'left',
    },
    paginationInput: {
      marginLeft: '10px',
      padding: '5px',
      borderRadius: '5px',
      width: 120,
      border: '1px solid rgba(0, 0, 0, 0.23)',
    },
    card: {
      boxShadow: '0px 5px 10px rgba(0,0,0,.1)!important',
      '& th:first-child, td:first-child': {
        paddingLeft: 20,
      },
      '& th:last-child, td:last-child': {
        paddingright: 20,
      },
    },
    payErrorTooltip: {
      verticalAlign: 'middle',
      margin: '0 0 0 5px',
      cursor: 'pointer',
      borderRadius: '50%',
      background: '#de5f5f',
      width: '17px',
      height: '17px',
      display: 'inline-block',
    },
    statusText: {
      borderRadius: 5,
      lineHeight: 1,
      padding: 10,
    },
    helpIcon: {
      fill: '#fff',
      fontSize: 14,
      marginTop: 1,
    },
    redStatus: {
      backgroundColor: '#fef2f2',
      color: '#9b2121',
    },
    greenStatus: {
      backgroundColor: '#f0fdf4',
      color: '#277143',
    },
    blueStatus: {
      backgroundColor: '#eff6ff',
      color: '#1d4ed8',
    },
    grayStatus: {
      backgroundColor: '#c5c3c3',
      color: '#696868',
    },
  })
);

export default reportStyle;
