import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Typography,
  Radio,
} from '@material-ui/core';

import { setRequestError } from 'actions/snackbar';
import { useAppDispatch } from 'hooks/reduxTypedHooks';
import { getUserConfig, setUserConfig } from 'actions/user';
import changePasswordStyle from 'view/changePassword/changePasswordStyle';

import useAuthStyles from '../signIn/signInStyle';

const PASSWORD_PERIOD = [
  { label: '1 месяц', value: '30' },
  { label: '3 месяца', value: '90' },
  { label: '6 месяцев', value: '180' },
  { label: '1 год', value: '360' },
  { label: 'Не требовать смены пароля', value: '0' },
];

const UserSettings = () => {
  const dispatch = useAppDispatch();

  const classes = { ...useAuthStyles(), ...changePasswordStyle() };

  const [passPeriod, handleChangePassPeriod] = useState('');

  useEffect(() => {
    getUserConfig().then((res) => {
      handleChangePassPeriod(
        res.data.result.configs['system.pswRotationDayCount']
      );
    });
  }, []);

  const handleSaveSettings = () => {
    if (passPeriod) {
      dispatch(setUserConfig(passPeriod));
    } else dispatch(setRequestError('Выберите частоту смены пароля', true));
  };

  return (
    <div className={`${classes.changePassCont} ${classes.container}`}>
      <form className={classes.changePasswordForm} noValidate>
        <Typography variant="h5">Настройки частоты смены пароля</Typography>
        <br />
        <FormControl component="fieldset">
          <RadioGroup
            row
            onChange={(e) => handleChangePassPeriod(e.target.value)}
            value={passPeriod}
          >
            <div style={{ textAlign: 'left' }}>
              {PASSWORD_PERIOD.map((item, key) => {
                return (
                  <div key={item.value}>
                    <FormControlLabel
                      label={item.label}
                      control={
                        <Radio
                          value={item.value}
                          color="primary"
                          className="radioButton"
                          aria-label={item.value}
                        />
                      }
                    />
                  </div>
                );
              })}
            </div>
          </RadioGroup>
        </FormControl>
        <Button
          className={classes.submit}
          variant="contained"
          color="primary"
          onClick={() => handleSaveSettings()}
        >
          Сохранить
        </Button>
      </form>
    </div>
  );
};

export default UserSettings;
