import React from 'react';
import InputMask from 'react-input-mask';
import { makeStyles } from '@material-ui/core/styles';
import { InputLabel, OutlinedInput, FormControl } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  err: {
    '& label': {
      color: 'red',
    },
  },
}));

interface IProps {
  mask: string,
  type?: string,
  id: string,
  placeholder: string,
  callback: (e: React.ChangeEvent<HTMLInputElement>) => void,
  styles: React.CSSProperties,
  name: string,
  defaultValue: string,
  error?: boolean,
}

const InputMaskCustom: React.FC<IProps> = ({
  mask,
  type,
  id,
  placeholder,
  callback,
  styles,
  name,
  defaultValue,
  error = false,
}) => {
  const classes = useStyles();

  return (
    <FormControl
      variant="outlined"
      fullWidth
      className={error ? classes.err : ''}
      aria-label="inputFormControl"
    >
      <InputLabel htmlFor={id}>{placeholder}</InputLabel>
      <InputMask
        mask={mask}
        maskChar={null}
        autoComplete="off"
        placeholder={placeholder}
        onChange={callback}
        style={styles}
        defaultValue={defaultValue}
        value={defaultValue}
        formatChars={{
          '*': '[A-Za-zа-яА-Я0-9@.-]',
          9: '[0-9]',
          a: '[А-яа-я ]',
        }}
      >
        {/* @ts-ignore */}
        {() => (
          <OutlinedInput
            label={`${placeholder}`}
            id={id}
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              'aria-label': 'weight',
            }}
            labelWidth={0}
            error={error}
            name={name}
            type={typeof type !== 'undefined' ? type : 'tel'}
          />
        )}
      </InputMask>
    </FormControl>
  );
};

export default InputMaskCustom;
