import { combineReducers, StateFromReducersMapObject, ActionFromReducersMapObject, Reducer } from 'redux';
import provider from './provider';
import snackbar from './snackbar';
import users from './users';

const reducers = {
  provider,
  snackbar,
  users,
};

type ReducersMapObject = typeof reducers;

const rootReducer: Reducer<
  StateFromReducersMapObject<ReducersMapObject>,
  ActionFromReducersMapObject<ReducersMapObject>
> = combineReducers(reducers);

export default rootReducer;
