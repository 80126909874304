import { makeStyles, createStyles } from '@material-ui/core/styles';
import signInImg from 'assets/img/test5.jpg';

const signInStyle = makeStyles((theme) =>
  createStyles({
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        height: '90vh',
      },
    },
    signInInput: {
      marginBottom: '10px',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },

    restorePass: {
      textDecoration: 'none',
      color: 'rgba(0, 0, 0, 0.54)',
    },
    form: {
      width: '60%', // Fix IE 11 issue.
      background: 'white',
      padding: '20px',
      textAlign: 'center',
      display: 'flex',
      flexFlow: 'column',
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      lineHeight: '2.85',
    },
    passBlock: {
      justifyContent: 'space-between',
    },
    containerWrapper: {
      textAlign: 'center',
      backgroundColor: '#ffffff',
    },
    signInContainer: {
      padding: '20px 50px',
      textAlign: 'left',
    },
    cashOutLogo: {
      width: '30px',
      margin: '15px',
    },
    signInImg: {
      backgroundImage: `url(${signInImg})`,
      backgroundSize: '100%',
      backgroundRepeat: 'no-repeat',
      height: '98vh',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    headerLogo: {
      width: '120px',
      height: '40px',
      marginLeft: '30px',
      marginRight: '40px',
    },
  })
);

export default signInStyle;
