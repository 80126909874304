import { AppDispatch } from 'types/redux';
import { TServiceList, IProviderList } from 'types/provider';

export const SET_PROVIDER_LIST = 'SET_PROVIDER_LIST';
export const START_LOADER = 'START_LOADER';
export const STOP_LOADER = 'STOP_LOADER';
export const SET_BASE64_JPEG = 'SET_BASE64_JPEG';
export const SET_UUID = 'SET_UUID';
export const SET_SERVICE_LIST = 'SET_SERVICE_LIST';
export const SET_IS_REQUESTED_SERVICE_LIST = 'SET_IS_REQUESTED_SERVICE_LIST';
export const SET_CURRENT_USER_PROVIDER = 'SET_CURRENT_USER_PROVIDER';

export const setServiceList =
  (list: TServiceList) => (dispatch: AppDispatch) => {
    dispatch({ type: SET_SERVICE_LIST, serviceList: list });
  };

export const setIsRequestedServiceList =
  (data: any) => (dispatch: AppDispatch) => {
    dispatch({
      type: SET_IS_REQUESTED_SERVICE_LIST,
      isRequestedServiceList: data,
    });
  };

export const setCurrentUserProfileProvider =
  (data: any) => (dispatch: AppDispatch) => {
    dispatch({ type: SET_CURRENT_USER_PROVIDER, data });
  };

export const setUuid = (id: string) => (dispatch: AppDispatch) => {
  dispatch({ type: SET_UUID, id: id });
};

export const setBase64JpegData = (data: any) => (dispatch: AppDispatch) => {
  dispatch({ type: SET_BASE64_JPEG, data: data });
};

export const setProviderList =
  (data: IProviderList) => (dispatch: AppDispatch) => {
    dispatch({ type: SET_PROVIDER_LIST, providerList: data });
  };

export const startLoader = () => (dispatch: AppDispatch) => {
  dispatch({ type: START_LOADER });
};

export const stopLoader = () => (dispatch: AppDispatch) => {
  dispatch({ type: STOP_LOADER });
};

interface ISetServiceListAction {
  type: typeof SET_SERVICE_LIST;
  serviceList: TServiceList;
}

interface ISetIsRequestedServiceListAction {
  type: typeof SET_IS_REQUESTED_SERVICE_LIST;
  isRequestedServiceList: any;
}

interface ISetCurrentUserProviderAction {
  type: typeof SET_CURRENT_USER_PROVIDER;
  data: any;
}

interface ISetUuidAction {
  type: typeof SET_UUID;
  id: string;
}

interface ISetBase64JpegAction {
  type: typeof SET_BASE64_JPEG;
  data: string;
}

interface ISetProviderListAction {
  type: typeof SET_PROVIDER_LIST;
  providerList: IProviderList;
}

interface IStartLoaderAction {
  type: typeof START_LOADER;
}

interface IStopLoaderAction {
  type: typeof STOP_LOADER;
}

export type TAction =
  | ISetServiceListAction
  | ISetIsRequestedServiceListAction
  | ISetCurrentUserProviderAction
  | ISetUuidAction
  | ISetBase64JpegAction
  | ISetProviderListAction
  | IStartLoaderAction
  | IStopLoaderAction;
