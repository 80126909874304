import { AppDispatch, RootState } from 'types/redux';
import { IAuthData } from './../types/auth';
import { v4 as uuidv4 } from 'uuid';

import {
  formatDate,
  setToLocalStorage,
  getFromLocalStorage,
  removeItemFromLocalStarage,
  printCheckFromImg,
  isSuccessStatusCode,
  getProviderListRequestNumberValue,
} from 'helpers/provider';
import { api } from 'instance/axiosIntanse';
import { redirectToPage } from 'actions/navigation';
import {
  setProviderList,
  setBase64JpegData,
  setUuid,
  setServiceList,
  setIsRequestedServiceList,
  setCurrentUserProfileProvider,
} from 'actions/provider';
import { errorsDefinition } from 'constants/main';
import { setRequestError, setSuccessMessage } from 'actions/snackbar';
import { AxiosResponse } from 'axios';
import { IProviderList } from 'types/provider';
import { IParams } from 'types/params';

export const GET_ACCESS_TOKEN = 'GET_ACCESS_TOKEN';

type TGetState = () => RootState;

export const sendAuthReq =
  ({ login, psw, captcha }: IAuthData) =>
  (dispatch: AppDispatch, store: TGetState) => {
    const captchaSession = captcha ? store().provider.uuid : '';
    return api
      .post(`/account/token`, {
        params: {
          lgn: login,
          psw: psw,
          captcha: captcha,
          captchaSession: captchaSession,
        },
      })
      .then((res: AxiosResponse<IProviderList>) => {
        const { data, status } = res;
        if (isSuccessStatusCode(status)) {
          if (data.code === 0) {
            setToLocalStorage('currentUser', login);
            setToLocalStorage('authUserData', data.result);
            removeItemFromLocalStarage('currentProvider');
            dispatch(setCurrentUserProfileProvider({}));
            dispatch(setIsRequestedServiceList(false));
            dispatch(redirectToPage('report'));
          } else {
            const errorText = errorsDefinition[data.code]
              ? errorsDefinition[data.code]
              : 'Ошибка авторизации, повторите запрос позднее';
            dispatch(setRequestError(errorText, true));
          }
        }
        return res;
      });
  };

export const changePasswordRequest =
  ({ login, oldPass, newPass, captcha }: IAuthData) =>
  (dispatch: AppDispatch, store: TGetState) => {
    const captchaSession = captcha ? store().provider.uuid : '';

    return api
      .post(`/account/change`, {
        params: {
          lgn: login,
          psw: oldPass,
          pswNew: newPass,
          captcha: captcha,
          captchaSession: captchaSession,
        },
      })
      .then((res) => {
        const { data, status } = res;
        if (isSuccessStatusCode(status)) {
          if (data.code === 0) {
            removeItemFromLocalStarage('authUserData');
            setToLocalStorage('authUserData', data.result);
            setToLocalStorage('IsCaptchaNeedForChangePassword', false);
            dispatch(setSuccessMessage('Пароль успешно изменен', true));
          } else {
            dispatch(setRequestError(errorsDefinition[data.code], true));
          }
        }
        return res;
      });
  };

export const sendRefreshToken = () => (dispatch: AppDispatch) => {
  const authData = getFromLocalStorage('authUserData');
  return api
    .post(`/account/refresh`, {
      params: {
        token: authData.token,
        refreshToken: authData.refreshToken,
      },
    })
    .then((res) => {
      const { data, status } = res;
      if (isSuccessStatusCode(status)) {
        if (data.code === 0) {
          setToLocalStorage('authUserData', data);
        } else {
          dispatch(setRequestError(errorsDefinition[data.code], true));
          dispatch(redirectToPage(''));
        }
      }
    });
};

export const getCaptcha = () => (dispatch: AppDispatch) => {
  let uuid = uuidv4();
  dispatch(setUuid(uuid));
  return api
    .post(`/account/captcha`, {
      params: {
        captchaSession: uuid,
      },
    })
    .then((res) => {
      const { data, status } = res;
      if (isSuccessStatusCode(status)) {
        dispatch(setBase64JpegData(data.result.captchaJpegBase64));
      }
    })
    .catch((err) => console.log('getCaptcha err:', err));
};

export const sendSmsPassword =
  ({ lgn, phone, captcha }: IAuthData) =>
  (dispatch: AppDispatch, store: TGetState) => {
    return api
      .post(`/account/forgot`, {
        params: {
          lgn: lgn,
          phone: phone,
          captcha: captcha,
          captchaSession: store().provider.uuid,
        },
      })
      .then((res) => {
        const { data } = res;
        if (data.Code !== 0) {
          dispatch(
            setRequestError(errorsDefinition[data.code] || data.message, true)
          );
          dispatch(getCaptcha());
        }
        return res;
      });
  };

export const printReceipt =
  (idTerminal?: number, sn?: string | null) => (dispatch: AppDispatch) => {
    return api
      .post(`/report/paymentcheck`, {
        params: {
          idTerminal: idTerminal,
          sn: String(sn),
          format: 'jpeg',
        },
      })
      .then((res) => {
        const { data, status } = res;

        if (isSuccessStatusCode(status)) {
          if (data.code === 0) {
            printCheckFromImg(data.result.check);
          } else {
            dispatch(
              setRequestError(errorsDefinition[data.code] || data.message, true)
            );
          }
        }
      });
  };

export const getServiceList = () => (dispatch: AppDispatch) => {
  return api
    .post(`/service/provuserservices`, {
      params: {},
    })
    .then((res) => {
      const { data, status } = res;

      if (isSuccessStatusCode(status)) {
        if (data.code === 0) {
          dispatch(setServiceList(data.data));
          dispatch(setIsRequestedServiceList(true));
        }
      }
      return res;
    });
};

export const logoutRequest = () => (dispatch: AppDispatch) => {
  const authData = getFromLocalStorage('authUserData');
  return api
    .post(`/account/logout`, {
      params: {
        refreshToken: authData?.refreshToken,
      },
    })
    .then((res) => {
      const { data, status } = res;
      if (isSuccessStatusCode(status)) {
        if (data.code === 0) {
          removeItemFromLocalStarage('authUserData');
          removeItemFromLocalStarage('currentUser');
          removeItemFromLocalStarage('currentProvider');
          dispatch(setIsRequestedServiceList(false));
          dispatch(setCurrentUserProfileProvider({}));
          dispatch(setServiceList([]));
          dispatch(redirectToPage(''));
        } else {
          dispatch(setRequestError(errorsDefinition[data.code], true));
          dispatch(redirectToPage(''));
        }
      }
    });
};

export const getProviderList =
  (
    {
      from,
      to,
      format,
      sn,
      account,
      idService,
      idProvider,
      columns,
      processingStatuses,
    }: IParams,
    showAlert?: boolean
  ) =>
  (dispatch: AppDispatch, getState: TGetState) => {
    const authData = getFromLocalStorage('authUserData');
    const xlsxType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    if (authData) {
      return api
        .post(
          `/report/paymentsprov`,
          {
            params: {
              dateFrom: from,
              dateTo: to,
              format: format,
              sn: sn !== null ? sn : '',
              account: account,
              idService: getProviderListRequestNumberValue(idService),
              idProvider: getProviderListRequestNumberValue(idProvider),
              columns,
              processingStatuses,
            },
          },
          // @ts-ignore
          { format: format }
        )
        .then((res) => {
          const { data, status } = res;
          if (isSuccessStatusCode(status)) {
            const checkLen = getCheckLength(
              data,
              res,
              xlsxType,
              // @ts-ignore
              format,
              getState,
              dispatch
            );
            if (!checkLen) return checkLen;

            if (data.code === 9) {
              return dispatch(setRequestError(data.message, true));
            }
            getProviderListErr(data, false, dispatch);
          }
        });
    }
  };

const getProviderListErr = (
  data: IProviderList,
  showAlert: any,
  dispatch: AppDispatch
) => {
  dispatch(setProviderList(data));
  if (data.code === 0) {
    if (data.data.length === 0 && showAlert) {
      dispatch(
        setRequestError('Платежей по заданным параметрам не найдено', true)
      );
    }
  } else {
    dispatch(setRequestError('Возникла ошибка при выполнении запроса', true));
  }
};

const getCheckLength = (
  data: any,
  res: AxiosResponse,
  xlsxType: string,
  format: string,
  getState: TGetState,
  dispatch: AppDispatch
) => {
  let result = true;

  if (
    // @ts-ignore
    (res.config.format === 'xlsx' && data.type === xlsxType) ||
    // @ts-ignore
    res.config.format === 'csv'
  ) {
    if (
      (getState().provider.providerList as IProviderList).data.length <= 300000
    ) {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `Отчет_по_платежам_${formatDate()}.${format}`
      );
      link.setAttribute('data-testid', 'downloadReport');

      document.body.appendChild(link);
      link.click();
      result = false;
    } else {
      dispatch(
        setRequestError(
          'Результат превысил 300тыс строк. Выгрузка в Excel не допускается. Измените параметры запроса так, чтобы сократить объем возвращаемых данных',
          true
        )
      );
    }
  }
  return result;
};
