import React from 'react';
import {
  Card,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Table,
  Paper,
  Typography,
} from '@material-ui/core';

import useStyles from './FaqStyle';

const APRequisites = () => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <Typography variant="overline">Реквизиты Astana-Plat</Typography>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableCell>Организация</TableCell>
              <TableCell>ТОО "Astana-Plat", РК, г.Алматы</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>БИН</TableCell>
              <TableCell>090440020689</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>ИИК</TableCell>
              <TableCell>№ KZ42998CTB0000011984</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Банк</TableCell>
              <TableCell>АФ АО "First Heartland Jysan Bank"</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>БИК</TableCell>
              <TableCell>TSESKZKZ</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default APRequisites;
