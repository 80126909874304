import { makeStyles, createStyles } from '@material-ui/core/styles';

const LayoutStyle = makeStyles(() =>
  createStyles({
    layoutAuthWrapper: {
      display: 'flex',
      flexFlow: 'column',
      position: 'relative',
      minHeight: '97vh',
      padding: '10px',
      backgroundColor: '#f7f7f7',
    },
    layoutWrapper: {
      display: 'flex',
      flexFlow: 'column',
      position: 'relative',
      minHeight: 'calc(100vh - 78px)',
      backgroundColor: '#fafafa',
    },
    layoutChildrenWrapper: {},
    snackbarAlertWrapper: {
      '& .MuiSnackbarContent-root': {
        background: '#049b0a',
      },
    },
    snackbarErrorWrapper: {
      '& .MuiSnackbarContent-root': {
        background: '#e45858',
      },
    },
    loaderWrapper: {
      position: 'fixed',
      background: 'rgba(137, 137, 137, 0.5)',
      width: '100vw',
      height: '100vh',
      zIndex: 99999,
    },

    loaderContainer: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      zIndex: 9999,
      '&.MuiCircularProgress-colorPrimary': {
        color: '#c4b24b',
      },
    },
  })
);

export default LayoutStyle;
