import React from 'react';
import * as Sentry from '@sentry/react';
import { Route, Router, Switch } from 'react-router-dom';

import history from 'appHistory';
import ErrorPage from 'view/ErrorPage';
import Report from 'view/report/report';
import SignIn from 'view/signIn/signIn';
import UserSettings from 'view/settings';
import Layout from 'components/layout/layout';
import SendPassword from 'view/sendPassword/sendPassword';
import ChangePassword from 'view/changePassword/changePassword';
import Faq from 'view/faq';

interface IProps {
  component: () => JSX.Element,
  layout: typeof Layout,
  [key: string]: any,
}

const AppPath: React.FC<IProps> = ({ component: Component, layout: LayoutWrap, ...rest }) => {
  return (
    // @ts-ignore
    <Route
      {...rest}
      render={(props) => {
        const layoutProps = {
          path: props.match.path,
        };

        return (
          <LayoutWrap {...layoutProps}>
            {/* @ts-ignore */}
            <Component {...props} />
          </LayoutWrap>
        );
      }}
    />
  );
};

const Routes = () => {
  return (
    <Sentry.ErrorBoundary fallback={ErrorPage}>
      <Router history={history}>
        <Switch>
          <AppPath exact path="/" component={SignIn} layout={Layout} />
          <AppPath path="/report" component={Report} layout={Layout} />
          <AppPath
            path="/change-password"
            component={ChangePassword}
            layout={Layout}
          />
          <AppPath
            path="/sendpassword"
            component={SendPassword}
            layout={Layout}
          />
          <AppPath path="/settings" component={UserSettings} layout={Layout} />
          <AppPath path="/faq" component={Faq} layout={Layout} />
          <AppPath path="*" exact={true} component={SignIn} layout={Layout} />
        </Switch>
      </Router>
    </Sentry.ErrorBoundary>
  );
};

AppPath.defaultProps = {
  match: {},
};

export default Routes;
