import { makeStyles, createStyles } from '@material-ui/core/styles';

const sendPasswordStyle = makeStyles((theme) =>
  createStyles({
    sendPasswordWrapper: {
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'center',
    },
    captchaWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '5px 0',
      '& .MuiInputBase-formControl': {
        margin: 0,
      },
      '& img': {
        marginRight: '5px',
      },
      [theme.breakpoints.down('xs')]: {
        flexFlow: 'column',
        '& img': {
          marginRight: '0',
          marginBottom: '5px',
        },
      },
    },
    backLink: {
      margin: '15px auto 100px 40px',
      '&:hover': {
        color: '#FFCC05',
      },
      color: '#108ee9',
      cursor: 'pointer',
      textDecoration: 'none',
    },
    sendPasswordInput: {
      margin: '10px 0 ',
      '& .MuiInputBase-formControl': {
        margin: 0,
      },
    },
    sendPasswordInputPhone: {
      '& input': { appearance: 'none', '-webkit-appearance': 'none' },
      '& input::-webkit-outer-spin-button,input::-webkit-inner-spin-button': {
        appearance: 'none',
        '-webkit-appearance': 'none',
      },
    },
    captchaInput: {
      '& input': {
        padding: '18.5px 40px',
      },
    },
    maskTelInput: {
      '& input': {
        width: '100%',
        borderRadius: '5px',
        border: '1px solid rgba(0, 0, 0, 0.23)',
        padding: '18.5px 14px',
        fontSize: '16px',
        boxSizing: 'border-box',
      },
      '& input:hover': {
        borderColor: 'rgba(0, 0, 0, 0.8)',
      },
      '& input:outline': {
        borderColor: '#fff229',
      },
      '& input > label': {
        display: 'none',
      },
      '& input:focused > label': {
        display: 'block',
        position: 'absolute',
        top: '0',
        left: '0',
        background: '#fff',
        fontSize: '11px',
      },
    },
  })
);

export default sendPasswordStyle;
