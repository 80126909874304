import React from 'react';
import {
  Card,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Table,
  Paper,
  Typography,
} from '@material-ui/core';

import { useAppSelector } from 'hooks/reduxTypedHooks';

import useStyles from './FaqStyle';

const BankRequisites = () => {
  const classes = useStyles();
  const { providerProfileInfo } = useAppSelector((state) => state.users);
  const {
    bin,
    kbe,
    actualAddress,
    address,
    transferRemuneration,
    transferProfileName,
  } = providerProfileInfo;

  const getTransferRemuneration = () => {
    if (transferRemuneration) {
      if (transferRemuneration === 1) return 'с вознаграждением';
      return 'без вознаграждения';
    }
    return '';
  };

  return (
    <Card className={classes.card}>
      <Typography variant="overline">Профиль</Typography>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableCell>Юридический адрес</TableCell>
              <TableCell>{address || 'Не указан'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Фактический адрес</TableCell>
              <TableCell>{actualAddress || 'Не указан'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>БИН</TableCell>
              <TableCell>{bin || 'Не указан'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Кбе</TableCell>
              <TableCell>{kbe || 'Не указан'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Перевод денег без вознаграждения</TableCell>
              <TableCell>{getTransferRemuneration() || 'Не указан'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Профиль оплаты</TableCell>
              <TableCell>{transferProfileName || 'Не указан'}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default BankRequisites;
