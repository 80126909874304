import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { getClearPhone } from 'helpers/provider';
import InputMask from 'components/inputMaskCustom';
import { redirectToPage } from 'actions/navigation';
import LogoKassa24 from 'assets/img/kassa24-black.svg';
import { getCaptcha, sendSmsPassword } from 'actions/api';
import { useAppSelector, useAppDispatch } from 'hooks/reduxTypedHooks';

import useStyles from './sendPasswordStyle';
import useAuthStyles from '../signIn/signInStyle';

const SendPassword = () => {
  const dispatch = useAppDispatch();
  const { base64Jpeg } = useAppSelector((state) => state.provider);

  const classes = { ...useStyles(), ...useAuthStyles() };
  const [error, setError] = useState(false);
  const [sendPasswordParams, setSendPassword] = useState({
    lgn: '',
    captcha: '',
    phone: '',
  });

  useEffect(() => {
    dispatch(getCaptcha());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.keyCode === 13) {
      handleSmsSubmit();
    }
  };
  const sendPasswordHandleChange = (e: any) => {
    let tempSendPasswordParams = { ...sendPasswordParams };
    tempSendPasswordParams = {
      ...tempSendPasswordParams,
      [e.target.name]: e.target.value,
    };

    setSendPassword(tempSendPasswordParams);
  };

  const handleValidate = () => {
    const { lgn, phone, captcha } = sendPasswordParams;

    if (!lgn || !phone || !captcha) {
      setError(true);
      return false;
    }
    return true;
  };

  const handleSmsSubmit = () => {
    const { lgn, phone, captcha } = sendPasswordParams;
    let phoneVal = getClearPhone(phone);

    const data = { lgn, phone: phoneVal, captcha };
    const valid = handleValidate();

    if (valid) {
      dispatch(sendSmsPassword(data));
    }
  };

  return (
    // @ts-ignore
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      spacing={1}
      className={classes.containerWrapper}
    >
      <Grid item sm={6} md={6} container>
        <Grid item sm={12} md={12} xs={12}>
          <img src={LogoKassa24} className={classes.headerLogo} alt="cashOut" />
        </Grid>
        <Grid item sm={12} md={12} xs={12}>
          <div className={classes.paper}>
            <form className={classes.form} noValidate>
              <Typography variant="h5">Восстановление пароля</Typography>
              <br />
              <Typography variant="body1" color="textSecondary">
                <Link
                  className={classes.backLink}
                  to="/"
                  onClick={() => dispatch(redirectToPage(''))}
                >
                  Вернуться на страницу авторизации
                </Link>
              </Typography>
              <br />

              <InputMask
                placeholder="Номер телефона"
                // @ts-ignore
                variant="outlined"
                name="phone"
                callback={sendPasswordHandleChange}
                error={error && sendPasswordParams.phone === ''}
                mask="8 (999) 999 99 99"
                type="tel"
              />

              <TextField
                fullWidth
                id="lgn"
                label="Логин"
                name="lgn"
                variant="outlined"
                className={classes.sendPasswordInput}
                onChange={(e) => sendPasswordHandleChange(e)}
                error={error && sendPasswordParams.lgn === ''}
                onKeyDown={(e) => handleKeypress(e)}
              />
              <div className={classes.captchaWrapper}>
                <img
                  alt="captcha"
                  src={`data:image/jpeg;base64,${base64Jpeg}`}
                />
                <TextField
                  id="captcha"
                  label="Введите код с картинки"
                  className={classes.captchaInput}
                  variant="outlined"
                  name="captcha"
                  error={error && sendPasswordParams.captcha === ''}
                  onChange={(e) => sendPasswordHandleChange(e)}
                  onKeyDown={(e) => handleKeypress(e)}
                />
              </div>

              <Button
                className={classes.submit}
                variant="contained"
                color="primary"
                onClick={handleSmsSubmit}
              >
                Восстановить пароль
              </Button>
            </form>
          </div>
        </Grid>
      </Grid>
      <Grid item sm={6} md={6} xs={6} className={classes.signInImg}></Grid>
    </Grid>
  );
};

export default SendPassword;
