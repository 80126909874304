import { TAction } from './../actions/provider';
import { Reducer } from 'redux';

import {
  SET_PROVIDER_LIST,
  START_LOADER,
  STOP_LOADER,
  SET_BASE64_JPEG,
  SET_UUID,
  SET_SERVICE_LIST,
  SET_IS_REQUESTED_SERVICE_LIST,
  SET_CURRENT_USER_PROVIDER,
} from 'actions/provider';
import { ICurrentUserProfileProvider, IProviderList, TServiceList } from 'types/provider';

export interface IProviderState {
  providerList: IProviderList,
  isLoading: boolean,
  base64Jpeg: string,
  uuid: string,
  serviceList: TServiceList,
  isRequestedServiceList: boolean,
  currentUserProfileProvider: ICurrentUserProfileProvider,
}

const getDefaultState = (): IProviderState => ({
  providerList: {} as IProviderList,
  isLoading: false,
  base64Jpeg: '',
  uuid: '',
  serviceList: [],
  isRequestedServiceList: false,
  currentUserProfileProvider: {} as ICurrentUserProfileProvider,
});

const provider: Reducer<IProviderState, TAction> = (stateVal, action) => {
  let state = stateVal;
  if (!state) state = getDefaultState();

  switch (action.type) {
    case SET_UUID:
      return { ...state, uuid: action.id };
    case SET_SERVICE_LIST:
      return { ...state, serviceList: action.serviceList };
    case SET_IS_REQUESTED_SERVICE_LIST:
      return {
        ...state,
        isRequestedServiceList: action.isRequestedServiceList,
      };
    case SET_BASE64_JPEG:
      return { ...state, base64Jpeg: action.data };
    case START_LOADER:
      return { ...state, isLoading: true };
    case STOP_LOADER:
      return { ...state, isLoading: false };
    case SET_PROVIDER_LIST:
      return {
        ...state,
        providerList: action.providerList,
      };
    case SET_CURRENT_USER_PROVIDER:
      return {
        ...state,
        currentUserProfileProvider: action.data,
      };
    default:
      return state;
  }
};

export default provider;
