import React from 'react';
import {
  Typography,
  Card,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Paper,
} from '@material-ui/core';

import { BOOKKEEPER_EMAIL, SUPPORT_EMAIL } from 'constants/main';
import { useAppSelector } from 'hooks/reduxTypedHooks';

import useStyles from './FaqStyle';

const Manager = () => {
  const classes = useStyles();
  const { providerProfileInfo } = useAppSelector((state) => state.users);
  const manager = providerProfileInfo?.manager || '';

  return (
    <Card className={[classes.card, classes.autoHeight].join(' ')}>
      <Typography variant="overline">Ваш менеджер</Typography>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableBody>
            {manager ? (
              <>
                <TableRow>
                  <TableCell>ФИО</TableCell>
                  <TableCell>{manager.fullName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>{manager.email}</TableCell>
                </TableRow>
              </>
            ) : (
              <TableRow>
                <TableCell>К вам не прикреплен менеджер</TableCell>
                <TableCell></TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>По вопросам поддержки</TableCell>
              <TableCell>
                <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Бухгалтер</TableCell>
              <TableCell>
                <a href={`mailto:${BOOKKEEPER_EMAIL}`}>{BOOKKEEPER_EMAIL}</a>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};
export default Manager;
