import * as Sentry from '@sentry/react';

export function logErrorToSentry(error: string) {
  if (!window.location.href.includes('localhost')) {
    Sentry.withScope(() => {
      Sentry.captureMessage(error);
    });
  }
}

export function logWarningToSentry(text: string) {
  if (!window.location.href.includes('localhost')) {
    Sentry.captureMessage(text, Sentry.Severity.Warning);
  }
}

export function logInfoToSentry(text: string) {
  if (!window.location.href.includes('localhost')) {
    Sentry.captureMessage(text, Sentry.Severity.Info);
  }
}
