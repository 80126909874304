import React, { useEffect } from 'react';
import { Typography, Grid } from '@material-ui/core';

import { getUserProfileByProvider } from 'actions/user';
import { useAppSelector, useAppDispatch } from 'hooks/reduxTypedHooks';

import Contract from './contract';
import APRequisites from './apRequisites';
import Instruction from './instruction';
import BankRequisites from './bankRequisites';
import Manager from './manager';
import Templates from './templates';

const Faq = () => {
  const dispatch = useAppDispatch();
  const { currentUserProfileProvider } = useAppSelector((state) => state.provider);
  const idProvider = currentUserProfileProvider?.id || 0;

  useEffect(() => {
    if (idProvider) dispatch(getUserProfileByProvider(idProvider));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserProfileProvider]);

  return (
    <div className="container">
      <Typography variant="h5" style={{ marginBottom: '30px' }}>
        FAQ
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Contract />
        </Grid>
        <Grid item xs={12} sm={6}>
          <APRequisites />
        </Grid>
        <Grid item xs={12} sm={6}>
          <BankRequisites />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Manager />
          <Instruction />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Templates />
        </Grid>
      </Grid>
    </div>
  );
};

export default Faq;
