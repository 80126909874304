import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import history from 'appHistory';
import { SENTRY_DSN } from 'constants/main';

import './index.css';
import Store from './configureStore';
import Routes from './routes';

const store = Store();

if (!window.location.href.includes('localhost')) {
  Sentry.init({
    normalizeDepth: 10,
    dsn: SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <Provider store={store}>
    <div className="App">
      <Routes />
    </div>
  </Provider>,
  document.getElementById('root')
);
