import { makeStyles, createStyles } from '@material-ui/core/styles';

const FooterStyle = makeStyles((theme) =>
  createStyles({
    main: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(2),
    },
    footer: {
      display: 'flex',
      position: 'absolute',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      top: '100%',
      width: '100%',
      padding: '8px 0',
      backgroundColor: '#eeeeee',
      color: '#000',
    },
    footerDetails: {
      display: 'flex',
      flexFlow: 'column',
    },
    footerDivider: {
      margin: '0 15px',
    },
    footerContactBlock: {
      borderRight: '1px solid lightgray',
    },
  })
);

export default FooterStyle;
