import { makeStyles, createStyles } from '@material-ui/core/styles';

const changePasswordStyle = makeStyles((theme) =>
  createStyles({
    container: {
      backgroundColor: '#fff',
      margin: '30px 40px',
      borderRadius: '5px',
      padding: '20px',
    },
    sendPasswordWrapper: {
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'center',
    },
    rule: {
      display: 'flex',
      '& svg': {
        marginRight: '10px',
      },
    },
    successText: {
      color: 'green',
    },

    changePasswordForm: {
      width: '350px', // Fix IE 11 issue.
      background: 'white',
      padding: '20px',
      textAlign: 'center',
      display: 'flex',
      flexFlow: 'column',
    },
    backLink: {
      margin: '15px auto 100px 40px',

      '&:hover': {
        color: '#FFCC05',
      },
      color: '#108ee9',
      cursor: 'pointer',
      textDecoration: 'none',
    },

    changePassCont: {
      display: 'flex',
      height: '70vh',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        flexFlow: 'column',
        height: 'auto',
      },
    },
    genNewPass: {
      textAlign: 'right',
      margin: '20px 5px 15px 5px',
      textDecoration: 'none',
      color: '#108ee9',
      fontSize: '14px',
    },
    successAlert: {
      background: '#91e091',
    },
    labelPassword: {
      marginLeft: '20px',
      padding: '20px',
      width: '300px',
      textAlign: 'left',
      [theme.breakpoints.down('xs')]: {
        margin: '0  0 0 0',
      },
    },
    captchaInput: {
      '& input': {
        padding: '18.5px 20px',
      },
    },
  })
);

export default changePasswordStyle;
