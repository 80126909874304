import React from 'react';
import { Typography, Divider } from '@material-ui/core';

import useStyles from './footerStyle';

const Copyright = () => {
  const year = new Date().getFullYear();

  return (
    <Typography variant="overline">
      © ТОО "Astana-Plat" (Астана-Плат), 2005 - {year}
    </Typography>
  );
};

const Footer = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <div className={classes.footerDetails}>
        <Typography variant="overline">
          тел: +7 (727) 356-52-30 вн. 5413 | email: prov@kassa24.kz
        </Typography>
      </div>
      <Divider
        orientation="vertical"
        flexItem
        className={classes.footerDivider}
      />
      <Copyright />
    </footer>
  );
};

export default Footer;
