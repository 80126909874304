import React from 'react';
import { Link } from 'react-router-dom';

const ErrorPage = () => (
  <div>
    <h1>Возникла непредвиденная ошибка</h1>
    <Link to="/">Перейти на главную</Link>
  </div>
);

export default ErrorPage;
