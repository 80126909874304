import React from 'react';
import {
  Typography,
  Card,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Paper,
} from '@material-ui/core';
import { GetApp } from '@material-ui/icons';

import useStyles from './FaqStyle';

const Templates = () => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <Typography variant="overline">Шаблоны</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                ДС-1 Astana-Plat добавление новых сервисов и изменении
                коммунальных услуг
              </TableCell>
              <TableCell>
                <a
                  className={classes.link}
                  href="/templates/dc_1_ap_dobalenie_novyh_servisov_izmenenie_com_uslug.docx"
                  download
                >
                  <GetApp />
                </a>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>ДС-2 Изменение расчетов</TableCell>
              <TableCell>
                <a
                  className={classes.link}
                  href="/templates/dc_2(izmenenie_raschetov).docx"
                  download
                >
                  <GetApp />
                </a>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>ДС-3 Изменение наименование и реквизитов</TableCell>
              <TableCell>
                <a
                  className={classes.link}
                  href="/templates/dc_3_izmenenie_naimenovanie_i_rekvizitov"
                  download
                >
                  <GetApp />
                </a>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};
export default Templates;
