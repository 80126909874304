import { makeStyles, createStyles } from '@material-ui/core/styles';

const ModalStyle = makeStyles((theme) =>
  createStyles({
    searchSubmit: {
      maxWidth: '100px',
      margin: '20px 20px 20px 0',
    },
    table: {
      '& .MuiTableHead-root th ': {
        backgroundColor: '#f5f5f5',
        padding: '5px',
        border: 'none',
      },
    },
    checked: {
      '& td': { backgroundColor: '#f5f5f5' },
    },
  })
);

export default ModalStyle;
