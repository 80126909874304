import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';

import { redirectToPage } from 'actions/navigation';
import LogoKassa24 from 'assets/img/kassa24-black.svg';
import { sendAuthReq, getCaptcha } from 'actions/api';
import { setToLocalStorage } from 'helpers/provider';
import { useAppSelector, useThunkAppDispatch } from 'hooks/reduxTypedHooks';

import useStyles from '../sendPassword/sendPasswordStyle';
import useAuthStyles from './signInStyle';

const SignIn = () => {
  const dispatch = useThunkAppDispatch();
  const base64Jpeg = useAppSelector((state) => state.provider.base64Jpeg);

  const [authData, setAuthData] = useState({
    login: '',
    psw: '',
    captcha: '',
  });
  const [errorInput, setErrorInput] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const classes = { ...useStyles(), ...useAuthStyles() };

  const handleClick = () => {
    if (
      authData.login !== '' &&
      authData.psw !== '' &&
      (!showCaptcha || (showCaptcha && authData.captcha))
    ) {
      setErrorInput(false);
      dispatch(sendAuthReq(authData)).then((res) => {
        if (res.data.code === 104) redirectToChangePassPage();
        else if (res.data.code !== 0) {
          if (Boolean(res.data.isCaptchaNeedForNextLogin)) {
            dispatch(getCaptcha());
            setShowCaptcha(true);
          }
        }
      });
    } else {
      setErrorInput(true);
    }
  };

  const handleKeypress = (
    e: React.KeyboardEvent<
      HTMLDivElement | HTMLTextAreaElement | HTMLInputElement
    >
  ) => {
    if (e.keyCode === 13) {
      handleClick();
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    let tempAuthData = {
      ...authData,
      [e.target.name]: e.target.value,
    };
    setAuthData(tempAuthData);
  };

  const redirectToChangePassPage = () => {
    setToLocalStorage('userLogin', authData.login);
    setTimeout(() => dispatch(redirectToPage('change-password')), 1000);
  };

  return (
    // @ts-ignore
    <Grid
      container
      justifycontent="center"
      alignItems="center"
      spacing={1}
      className={classes.containerWrapper}
    >
      <Grid item sm={6} md={6} container>
        <Grid item sm={12} md={12} xs={12}>
          <img src={LogoKassa24} className={classes.headerLogo} alt="cashOut" />
        </Grid>
        <Grid item sm={12} md={12} xs={12}>
          <div className={classes.paper}>
            <form className={classes.form} noValidate>
              <Typography variant="h5">Авторизация</Typography>
              <br />
              <Typography variant="body1" color="textSecondary">
                Приветствуем Вас в кабинете провайдера «Касса24»
              </Typography>
              <br />
              <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Логин
                </InputLabel>
                <OutlinedInput
                  autoComplete="off"
                  error={errorInput && authData.login === ''}
                  // @ts-ignore
                  variant="outlined"
                  className={classes.signInInput}
                  id="login"
                  label="Логин"
                  name="login"
                  onChange={(e) => handleChange(e)}
                  onKeyDown={(e) => handleKeypress(e)}
                  autoFocus
                  labelWidth={70}
                  inputProps={{ 'aria-label': 'login' }}
                />
              </FormControl>

              <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Пароль
                </InputLabel>
                <OutlinedInput
                  autoComplete="off"
                  // @ts-ignore
                  variant="outlined"
                  error={errorInput && authData.psw === ''}
                  className={classes.signInInput}
                  onChange={(e) => handleChange(e)}
                  onKeyDown={(e) => handleKeypress(e)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <VisibilityOffIcon aria-label="visibleOff" />
                        ) : (
                          <VisibilityIcon aria-label="visibleOn" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  name="psw"
                  label="Пароль"
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  labelWidth={70}
                  inputProps={{ 'aria-label': 'password' }}
                />
              </FormControl>

              {showCaptcha && (
                <div className={classes.captchaWrapper}>
                  <img
                    alt="captcha"
                    src={`data:image/jpeg;base64,${base64Jpeg}`}
                  />
                  <TextField
                    id="captcha"
                    label="Введите код с картинки"
                    className={classes.captchaInput}
                    variant="outlined"
                    name="captcha"
                    error={errorInput && authData.captcha === ''}
                    onChange={(e) => handleChange(e)}
                    onKeyDown={(e) => handleKeypress(e)}
                  />
                </div>
              )}
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => handleClick()}
              >
                Войти
              </Button>
              <Grid
                container
                // @ts-ignore
                className={classes.restorePassWrapper}
              >
                <Grid item xs>
                  <Link
                    to="#"
                    className={classes.restorePass}
                    onClick={() => dispatch(redirectToPage('sendpassword'))}
                  >
                    Забыли пароль?
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
        </Grid>
      </Grid>
      <Grid item sm={6} md={6} xs={6} className={classes.signInImg}></Grid>
    </Grid>
  );
};

export default SignIn;
