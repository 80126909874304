import React, { useCallback } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import useStyles from 'components/layout/layoutStyle';
import { setRequestError, setSuccessMessage } from 'actions/snackbar';
import { redirectToPage } from 'actions/navigation';
import { AppDispatch, RootState } from 'types/redux';

interface IProps {
  isRequestError: boolean,
  errorMessage: string,
  setRequestError: (message: string, isRequestError: boolean) => void,
  setSuccessMessage: (message: string, isAlertMessage: boolean) => void,
  successMessage: string,
  isAlertMessage: boolean,
}

const SnackbarComponent: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const handleCloseSnackbar = useCallback((_: React.SyntheticEvent<any, Event>, reason: string) => {
    if (reason === 'timeout') {
      props.setSuccessMessage('', false);
      props.setRequestError('', false);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Snackbar
        data-testid="snackBarWrapper"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={props.isRequestError || props.isAlertMessage}
        autoHideDuration={6000}
        className={
          (props.errorMessage && classes.snackbarErrorWrapper) ||
          (props.successMessage && classes.snackbarAlertWrapper)
        }
        onClose={handleCloseSnackbar}
        message={`${props.errorMessage || props.successMessage}`}
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  isRequestError: state.snackbar.isRequestError,
  isAlertMessage: state.snackbar.isAlertMessage,
  errorMessage: state.snackbar.errorMessage,
  successMessage: state.snackbar.successMessage,
});

const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators(
    {
      setRequestError,
      setSuccessMessage,
      redirectToPage,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SnackbarComponent);
