import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import generator from 'generate-password';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Button,
  Typography,
  Paper,
  TextField,
  InputAdornment,
  IconButton,
} from '@material-ui/core';

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';

import { redirectToPage } from 'actions/navigation';
import { changePasswordRequest, getCaptcha } from 'actions/api';
import { getFromLocalStorage, setToLocalStorage } from 'helpers/provider';
import { useAppSelector, useThunkAppDispatch } from 'hooks/reduxTypedHooks';

import useStyles from './changePasswordStyle';
import useSendPassStyles from '../sendPassword/sendPasswordStyle';
import useAuthStyles from '../signIn/signInStyle';
import { IPassword } from 'types/auth';

interface IPassErrors {
  textlength: boolean;
  uppercase: boolean;
  number: boolean;
  lowercase: boolean;
  specialCharacter: boolean;
  thePasswordsMatch: boolean;
}

type TPassErrorsKey = keyof IPassErrors;

interface IInputName {
  name: string;
}

const ChangePassword = () => {
  const classes = {
    ...useStyles(),
    ...useAuthStyles(),
    ...useSendPassStyles(),
  };
  const base64Jpeg = useAppSelector((state) => state.provider.base64Jpeg);

  const [passErrors, setPassError] = useState<IPassErrors>({
    textlength: false,
    uppercase: false,
    number: false,
    lowercase: false,
    specialCharacter: false,
    thePasswordsMatch: false,
  });
  const [showPassword, setShowPassword] = useState<string | boolean>(false);
  const [error, setError] = useState(false);
  const [showInputName, setShowInputName] = useState<IInputName | {}>({});
  const [passwords, setPasswords] = useState<IPassword>({
    oldPass: '',
    newPass: '',
    newPassRepeat: '',
    captcha: '',
  });
  const [showCaptcha, setShowCaptcha] = useState(false);

  const { newPass, newPassRepeat, oldPass, captcha } = passwords;
  const userLogin = getFromLocalStorage('userLogin');
  const currentUser = getFromLocalStorage('currentUser');

  const dispatch = useThunkAppDispatch();

  useEffect(() => {
    checkPassword(newPass);
  }, [newPass]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    checkPassword(newPassRepeat);
  }, [newPassRepeat]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const IsCaptchaNeedForChangePassword = getFromLocalStorage(
      'IsCaptchaNeedForChangePassword'
    );
    const TimeForShowCaptcha = getFromLocalStorage('TimeForShowCaptcha');
    const dateNow = new Date();

    if (TimeForShowCaptcha) {
      const dateDiff = Math.floor(
        (dateNow.getTime() - new Date(TimeForShowCaptcha).getTime()) /
          (1000 * 60)
      );

      if (dateDiff >= 30) {
        setToLocalStorage('IsCaptchaNeedForChangePassword', false);
      } else {
        dispatch(getCaptcha());
        setShowCaptcha(IsCaptchaNeedForChangePassword);
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const checkPassword = useCallback(
    (p: string) => {
      if (typeof p === 'undefined') return;
      let tempPassError = {
        ...passErrors,
      };
      const regex = /\d/;
      const regexCharLower = /[a-z]+/;
      const regexCharUpper = /[A-Z]+/;
      const regexNumber = /(?=.{8,})/g;
      const regexAll = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/g;
      const hasDigit = regex.test(p);
      const hasDigitCharUpper = regexCharUpper.test(p);
      const hasDigitCharLower = regexCharLower.test(p);
      const hasDigitNumber = regexNumber.test(p);
      const hasDigitAll = regexAll.test(p);

      if (hasDigit) {
        tempPassError = {
          ...tempPassError,
          number: true,
        };
      } else {
        tempPassError = {
          ...tempPassError,
          number: false,
        };
      }

      if (hasDigitCharLower) {
        tempPassError = {
          ...tempPassError,
          lowercase: true,
        };
      } else {
        tempPassError = {
          ...tempPassError,
          lowercase: false,
        };
      }

      if (hasDigitCharUpper) {
        tempPassError = {
          ...tempPassError,
          uppercase: true,
        };
      } else {
        tempPassError = {
          ...tempPassError,
          uppercase: false,
        };
      }

      if (hasDigitNumber) {
        tempPassError = {
          ...tempPassError,
          textlength: true,
        };
      } else {
        tempPassError = {
          ...tempPassError,
          textlength: false,
        };
      }

      if (hasDigitAll) {
        tempPassError = {
          ...tempPassError,
          specialCharacter: true,
        };
      } else {
        tempPassError = {
          ...tempPassError,
          specialCharacter: false,
        };
      }

      if (newPass === newPassRepeat && newPass) {
        tempPassError = {
          ...tempPassError,
          thePasswordsMatch: true,
        };
      } else {
        tempPassError = {
          ...tempPassError,
          thePasswordsMatch: false,
        };
      }

      setPassError(tempPassError);
    },
    [passErrors, newPassRepeat, newPass]
  );

  const generatePassword = () => {
    const password = generator.generate({
      length: 8,
      numbers: true,
      uppercase: true,
      lowercase: true,
      symbols: true,
      exclude: `}{-[]~"'()//|^,<>`,
      strict: true,
    });

    let tempSendPasswordParams = { ...passwords };
    tempSendPasswordParams = {
      ...tempSendPasswordParams,
      newPass: password,
      newPassRepeat: password,
    };

    setPasswords(tempSendPasswordParams);
  };

  const handleKeypress = (e: React.KeyboardEvent) => {
    if (e.keyCode === 13) {
      handleSmsSubmit();
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let tempSendPasswordParams = { ...passwords };
    tempSendPasswordParams = {
      ...tempSendPasswordParams,
      [e.target.name]: e.target.value,
    };

    setPasswords(tempSendPasswordParams);
  };

  const checkFieldsForSmsSend = () => {
    return (
      (oldPass !== '' && newPass !== '' && !showCaptcha) ||
      (showCaptcha && passwords.captcha)
    );
  };

  const checkUserLoginForRedirect = () => {
    if (userLogin) dispatch(redirectToPage(''));
  };

  const handleSmsSubmit = () => {
    let reg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9]).{8,}$/;
    const login = currentUser !== null ? currentUser : userLogin;

    if (checkFieldsForSmsSend()) {
      if (reg.test(newPass)) {
        dispatch(
          changePasswordRequest({ login, oldPass, newPass, captcha })
        ).then((res) => {
          if (res.data.Code === 100) {
            if (Boolean(res.data.isCaptchaNeedForNextLogin)) {
              dispatch(getCaptcha());
              setShowCaptcha(true);
              setToLocalStorage('IsCaptchaNeedForChangePassword', true);
              setToLocalStorage('TimeForShowCaptcha', new Date());
            }
          }
        });
        setToLocalStorage('userLogin', '');
        checkUserLoginForRedirect();
        setError(false);
        setPasswords({
          oldPass: '',
          newPass: '',
          newPassRepeat: '',
        });
        setPassError({
          textlength: false,
          uppercase: false,
          number: false,
          lowercase: false,
          specialCharacter: false,
          thePasswordsMatch: false,
        });
      }
    } else {
      setError(true);
    }
  };

  const togglePassword = (name: string) => {
    setShowPassword(name);
    setShowInputName({
      ...showInputName,
      name: name,
    });

    setShowPassword(!showPassword);
  };

  const getInputType = (name: string) => {
    if ((showInputName as IInputName).name === name && showPassword)
      return 'text';

    return 'password';
  };

  const getVisibiltyIcon = (name: string) => {
    if ((showInputName as IInputName).name === name && showPassword)
      return <VisibilityOffIcon aria-label={`${name}Off`} />;

    return <VisibilityIcon aria-label={`${name}On`} />;
  };

  const getValidateStatus = (name: TPassErrorsKey) => {
    if (passErrors[name]) return <CheckCircleIcon />;
    return <RadioButtonUnchecked />;
  };

  return (
    <div className={`${classes.changePassCont} ${classes.container}`}>
      <form className={classes.changePasswordForm} noValidate>
        <Typography variant="h5">Сменить пароль</Typography>
        <br />
        <FormControl variant="outlined">
          <InputLabel htmlFor="oldPass">Введите старый пароль</InputLabel>
          <OutlinedInput
            id="oldPass"
            name="oldPass"
            value={passwords.oldPass}
            aria-describedby="component-outlined-text"
            onChange={(e) => handleInputChange(e)}
            label="Введите старый пароль"
            onKeyDown={(e) => handleKeypress(e)}
            error={error && passwords.oldPass === ''}
            inputProps={{ 'aria-label': 'oldPass' }}
          />
          {error && passwords.oldPass === '' && (
            <FormHelperText id="component-outlined-text">
              Поле обязательно для заполнения
            </FormHelperText>
          )}
        </FormControl>
        <Link
          onClick={() => generatePassword()}
          to="#"
          className={classes.genNewPass}
        >
          Сгенерировать пароль
        </Link>
        <FormControl variant="outlined">
          <InputLabel htmlFor="newPass">Введите новый пароль</InputLabel>
          <OutlinedInput
            id="newPass"
            value={passwords.newPass}
            type={getInputType('newPass')}
            name="newPass"
            aria-describedby="component-outlined-text"
            onChange={(e) => handleInputChange(e)}
            label="Введите новый пароль"
            onKeyDown={(e) => handleKeypress(e)}
            error={error && passwords.newPass === ''}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggleNewpassVisibility"
                  onClick={() => togglePassword('newPass')}
                >
                  {getVisibiltyIcon('newPass')}
                </IconButton>
              </InputAdornment>
            }
            inputProps={{ 'aria-label': 'newPass' }}
          />

          {error && passwords.newPass === '' && (
            <FormHelperText id="component-outlined-text">
              Поле обязательно для заполнения
            </FormHelperText>
          )}
        </FormControl>

        <br />
        <FormControl variant="outlined">
          <InputLabel htmlFor="newPassRepeat">
            Повторите новый пароль
          </InputLabel>
          <OutlinedInput
            id="newPassRepeat"
            type={getInputType('newPassRepeat')}
            value={passwords.newPassRepeat}
            name="newPassRepeat"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggleNewpassrepeatVisibility"
                  onClick={() => togglePassword('newPassRepeat')}
                >
                  {getVisibiltyIcon('newPassRepeat')}
                </IconButton>
              </InputAdornment>
            }
            aria-describedby="component-outlined-text"
            onChange={(e) => handleInputChange(e)}
            label="Повторите новый пароль"
            onKeyDown={(e) => handleKeypress(e)}
            error={error && passwords.newPassRepeat === ''}
            inputProps={{ 'aria-label': 'newPassRepeat' }}
          />

          {error && passwords.newPassRepeat === '' && (
            <FormHelperText id="component-outlined-text">
              Поле обязательно для заполнения
            </FormHelperText>
          )}
        </FormControl>
        {showCaptcha && (
          <div className={classes.captchaWrapper}>
            <img alt="captcha" src={`data:image/jpeg;base64,${base64Jpeg}`} />
            <TextField
              id="captcha"
              label="Введите код с картинки"
              className={classes.captchaInput}
              variant="outlined"
              name="captcha"
              error={error && passwords.captcha === ''}
              onChange={(e) => handleInputChange(e)}
              onKeyDown={(e) => handleKeypress(e)}
            />
          </div>
        )}

        <Button
          className={classes.submit}
          variant="contained"
          color="primary"
          onClick={() => handleSmsSubmit()}
        >
          Сменить пароль
        </Button>
      </form>
      <Paper className={`${classes.labelPassword} `}>
        <h4>Требования к паролю</h4>
        <p
          className={`${passErrors.textlength && classes.successText} ${
            classes.rule
          }`}
        >
          {getValidateStatus('textlength')}
          Не менее 8 символов
        </p>
        <p
          className={`${passErrors.uppercase && classes.successText} ${
            classes.rule
          }`}
        >
          {getValidateStatus('uppercase')}
          Минимум одна заглавная латинская буква
        </p>
        <p
          className={`${passErrors.lowercase && classes.successText} ${
            classes.rule
          }`}
        >
          {getValidateStatus('lowercase')}
          Минимум одна строчная латинская буква
        </p>
        <p
          className={`${passErrors.number && classes.successText} ${
            classes.rule
          }`}
        >
          {getValidateStatus('number')} Минимум одна цифра
        </p>
        <p
          className={`${passErrors.specialCharacter && classes.successText} ${
            classes.rule
          }`}
        >
          {getValidateStatus('specialCharacter')} Минимум один спец. символ
        </p>
        <p
          className={`${passErrors.thePasswordsMatch && classes.successText} ${
            classes.rule
          }`}
        >
          {getValidateStatus('thePasswordsMatch')}
          Новые пароли совпадают
        </p>
      </Paper>
    </div>
  );
};

export default ChangePassword;
