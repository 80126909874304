import React from 'react';
import {
  Card,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Table,
  Paper,
  Typography,
} from '@material-ui/core';
import { CheckBox, IndeterminateCheckBox } from '@material-ui/icons';

import useStyles from './FaqStyle';
import { IContract } from 'types/provider';
import { splitDateString } from 'helpers/provider';
import { useAppSelector } from 'hooks/reduxTypedHooks';

const Contract = () => {
  const classes = useStyles();

  const { providerProfileInfo } = useAppSelector((state) => state.users);
  const contract = providerProfileInfo?.contract || '';

  return (
    <Card className={classes.card}>
      <Typography variant="overline">Договор</Typography>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableCell>Номер договора</TableCell>
              <TableCell> {(contract as IContract)?.number || 'Не указан'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Дата заключения договора</TableCell>
              <TableCell>
                {(contract as IContract)?.from ? splitDateString(String((contract as IContract)?.from)) : '-'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Дата окончания договора</TableCell>
              <TableCell>
                {(contract as IContract)?.to ? splitDateString(String((contract as IContract)?.to)) : '-'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Пролонгация договора</TableCell>
              <TableCell>
                {(contract as IContract)?.prolongation === 0 ? (
                  <IndeterminateCheckBox />
                ) : (
                  <CheckBox />
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default Contract;
