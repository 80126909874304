import { AppDispatch } from 'types/redux';
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export const SET_SUCCESS_ALERT_MESSAGE = 'SET_SUCCESS_ALERT_MESSAGE';

export const setSuccessMessage =
  (message: string, isAlertMessage: boolean) => (dispatch: AppDispatch) => {
    dispatch({
      type: SET_SUCCESS_ALERT_MESSAGE,
      successMessage: message,
      isAlertMessage,
    });
  };

export const setRequestError =
  (message: string, isRequestError: boolean) => (dispatch: AppDispatch) => {
    dispatch({
      type: SET_ERROR_MESSAGE,
      isRequestError,
      errorMessage: message,
    });
  };

interface ISetRequestErrorAction {
  type: typeof SET_ERROR_MESSAGE;
  isRequestError: boolean;
  errorMessage: string;
}

interface ISetSuccessMessageAction {
  type: typeof SET_SUCCESS_ALERT_MESSAGE;
  successMessage: string;
  isAlertMessage: boolean;
}

export type IAction = ISetRequestErrorAction | ISetSuccessMessageAction;

export type TSetSuccessMessage = typeof setSuccessMessage;
export type TSetRequestError = typeof setRequestError;
