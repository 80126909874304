import { QuestionAnswer, SearchOutlined } from '@material-ui/icons';

export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN || '';

export const errorsDefinition: { [keys: number]: string } = {
  1: 'Этот пароль есть в списках украденных паролей. Придумайте другой пароль.',
  4: 'Внутренняя ошибка',
  100: 'Неправильное имя пользователя или пароль',
  101: 'Aгент заблокирован',
  102: 'Пользователь заблокирован',
  103: 'Использование учетной записи приостановлено',
  104: 'Cрок действия пароля истек. Необходимо сменить пароль',
  134: 'Капча не прошла проверку',
  210: 'Сброс пароля не может быть выполнен прямо сейчас. Со времени последнего сброса пароля прошло менее 15 мин.',
};

export const PERSONAL_MENU = [
  { link: 'change-password', title: 'Сменить пароль' },
] as const;
export const LINK_FOR_SHOW_SEARCH_PAYS = [
  '/settings',
  '/change-password',
  '/faq',
] as const;
export const HEADER_MENU = [
  { name: 'Поиск платежей', link: '/report', Icon: SearchOutlined },
  { name: 'FAQ', link: '/faq', Icon: QuestionAnswer },
] as const;
export const BOOKKEEPER_EMAIL = 'buh@kassa24.kz';
export const SUPPORT_EMAIL = 'b2b@kassa24.kz';
export const PROV_EMAIL = 'prov@kassa24.kz';
export const SERVICE_LIST_SUCCESS_CODE = 0;
