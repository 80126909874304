import React from 'react';

import { REPORT_PROCESSING_STATUSES } from 'constants/search';
import { IParams } from 'types/params';
import { TServiceList } from 'types/provider';

export const setSortByProviderHandler = (propertyParams: IParams, setSortByProvider: any) => {
  if (propertyParams.idProvider) {
    setSortByProvider(true);
  } else if (propertyParams.idService) {
    setSortByProvider(false);
  } else {
    setSortByProvider(false);
  }
};

export const filterServiceList = (serviceList: any, setFilteredServiceList: any) => {
  if (serviceList.length) {
    const sortedServices: TServiceList = [];
    Object.keys(serviceList).forEach((item) => {
      serviceList[item].services.forEach((service: any) => {
        const found = sortedServices.some((el) => el.id === service.id);
        if (!found) {
          sortedServices.push(service);
        }
      });
    });
    setFilteredServiceList(sortedServices);
  }
};

export const setElementFocus = (
  firstUpdate: React.MutableRefObject<boolean>,
  ButtonElement: React.MutableRefObject<HTMLButtonElement | null>,
) => {
  if (firstUpdate.current) {
    firstUpdate.current = false;
  } else {
    ButtonElement.current?.focus();
  }
};

export const checkProviderProperty = (
  tempSearchParams: IParams,
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | { name: string; value: string; }>,
) => {
  if (e.target.name === 'idProvider') {
    tempSearchParams = { ...tempSearchParams, idService: '' };
  }
  return tempSearchParams;
};

export const getInputFieldCorrectValue = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | { name?: string; value: string; }>) => {
  let value: string | number[] = e.target.value;

  if (e.target.name === 'processingStatuses' && value.includes('all')) {
    const selectStatusesKeys: number[] = [];
    if (value.length !== REPORT_PROCESSING_STATUSES.length + 1) {
      for (let i = 0; i < REPORT_PROCESSING_STATUSES.length; i++) {
        selectStatusesKeys.push(i);
      }
    }
    value = selectStatusesKeys;
  }

  return value;
};

export const setDatesValueByName = (
  setDateFrom: React.Dispatch<React.SetStateAction<Date | number>>,
  setDateTo: React.Dispatch<React.SetStateAction<Date | number>>,
  val: Array<Date | number>,
  name: string,
) => {
  if (name === 'from') setDateFrom(val[0]);
  else if (name === 'to') setDateTo(val[0]);
};

export const getRequestPropertyParams = (params: IParams, propertyParams: IParams) => {
  if (params.idProvider) {
    return params;
  }
  return { ...propertyParams };
};

export const checkPressKeyCode = (
  e: React.KeyboardEvent,
  fetchProviderList: (param?: IParams) => void,
) => {
  if (e.keyCode === 13) {
    fetchProviderList();
  }
};
