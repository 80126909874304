import React, { useRef, useLayoutEffect, useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';
import 'flatpickr/dist/flatpickr.css';
import { Russian } from 'flatpickr/dist/l10n/ru';
import Flatpickr from 'react-flatpickr';
import {
  TextField,
  FormControl,
  Button,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
  ListItemText,
  Divider,
} from '@material-ui/core';

import {
  EXPORT_REPORT_TYPES,
  REPORT_PROCESSING_STATUSES,
} from 'constants/search';
import { formatDate } from 'helpers/provider';
import Modal from 'components/exportModal';
import { setRequestError } from 'actions/snackbar';
import { getProviderList } from 'actions/api';
import {
  checkProviderProperty,
  setElementFocus,
  getInputFieldCorrectValue,
  setDatesValueByName,
  getRequestPropertyParams,
  checkPressKeyCode,
} from 'helpers/search';
import { checkDataAndFileTypeBeforeDownloadHandler } from 'actions/search';
import { useAppSelector, useAppDispatch } from 'hooks/reduxTypedHooks';

import useStyles from './searchStyle';
import {
  IParams,
  IParamsFields,
  TExportType,
  TParamsColumns,
} from 'types/params';

type TFlatpickrValue = Date[];
type TFlatpickrKeyboardEvent = React.KeyboardEvent<HTMLInputElement>;

interface IProps {
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
}

const Search: React.FC<IProps> = (props) => {
  const dispatch = useAppDispatch();
  const { serviceList, providerList, currentUserProfileProvider } =
    useAppSelector((state) => state.provider);

  const today = new Date();
  const tomorrow = new Date(today);
  const [propertyParams, setParamsForGetProviderListRequest] =
    useState<IParams>({
      sn: null,
      account: '',
      idService: '',
      idProvider: currentUserProfileProvider?.id,
      processingStatuses: [],
    });

  const [dateFrom, setDateFrom] = useState<Date | number>(new Date());
  const [dateTo, setDateTo] = useState<Date | number>(
    tomorrow.setDate(tomorrow.getDate() + 1)
  );
  const classes = useStyles();
  const ButtonElement = useRef(null);
  const firstUpdate = useRef(true);

  const [settingsModal, showSettingsModal] = useState(false);
  const [exportType, setExportType] = useState('xlsx');

  useEffect(() => {
    props.setOrderBy('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentUserProfileProvider?.id) {
      const params = {
        sn: null,
        account: '',
        idService: '',
        processingStatuses: [],
        idProvider: currentUserProfileProvider?.id,
      };
      setParamsForGetProviderListRequest({
        ...params,
      });
      fetchProviderList(params);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserProfileProvider?.id]);

  useLayoutEffect(() => {
    setElementFocus(firstUpdate, ButtonElement);
  }, [dateFrom, dateTo]);

  const onFilterValueChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | { name: string; value: string }
    >
  ) => {
    let tempSearchParams = { ...propertyParams };
    const value = getInputFieldCorrectValue(e);

    tempSearchParams = {
      ...tempSearchParams,
      [e.target.name]: value,
    };
    tempSearchParams = checkProviderProperty(tempSearchParams, e);

    setParamsForGetProviderListRequest(tempSearchParams);
  };

  const handleCalendarChange = (val: TFlatpickrValue, name: string) => {
    setDatesValueByName(setDateFrom, setDateTo, val, name);
  };

  const resetSearchInputs = () => {
    setParamsForGetProviderListRequest({
      sn: null,
      account: '',
      idService: '',
      idProvider: '',
      processingStatuses: [],
    });
  };

  const downloadReport = (type: TExportType, columns: TParamsColumns = []) => {
    let data: IParams = {
      from: formatDate(dateFrom, 3),
      to: formatDate(dateTo, 2),
      format: type,
      idProvider: propertyParams.idProvider,
    };
    if (type === 'csv') data.columns = columns;

    dispatch(getProviderList(data));
  };

  const fetchProviderList = (params = {}) => {
    let preparedData = {
      from: formatDate(dateFrom, 3),
      to: formatDate(dateTo, 2),
    };

    const requestPropertyParams = getRequestPropertyParams(
      params,
      propertyParams
    );

    dispatch(
      getProviderList({ ...preparedData, ...requestPropertyParams }, true)
    );
  };

  const handleKeypress = (e: React.KeyboardEvent) => {
    checkPressKeyCode(e, fetchProviderList);
  };

  const checkDataAndFileTypeBeforeDownload = (type: TExportType) => {
    providerList &&
      dispatch(
        checkDataAndFileTypeBeforeDownloadHandler(
          type,
          providerList,
          setExportType,
          showSettingsModal,
          downloadReport,
          setRequestError
        )
      );
  };

  const getFieldValue = (field: IParamsFields) => {
    return propertyParams[field] || '';
  };

  const getRenderValueForSelectStatuses = (data: number[]) => {
    const selectValuesText: string[] = [];
    data.forEach((value: number) => {
      const filteredStatus = REPORT_PROCESSING_STATUSES[value];
      selectValuesText.push(filteredStatus);
    });
    return selectValuesText.join(', ');
  };

  return (
    <>
      {settingsModal && (
        <>
          <Modal
            downloadReport={downloadReport}
            exportType={exportType}
            onClose={() => showSettingsModal(false)}
          />
        </>
      )}

      <div className={classes.searchWrapper}>
        <div className={classes.resetWrapper}>
          <Link
            to="#"
            onClick={resetSearchInputs}
            className={classes.resetLink}
          >
            Сбросить фильтры
          </Link>
          <div className={classes.searchBlockContainer}>
            <div className={classes.calendarInput}>
              <Flatpickr
                id="dateFrom"
                placeholder="Выберите период"
                options={{
                  dateFormat: 'd-m-Y',
                  locale: Russian,
                }}
                className={classes.calendarWrapper}
                onChange={(val: TFlatpickrValue) =>
                  handleCalendarChange(val, 'from')
                }
                value={dateFrom}
                onKeyDown={(e: TFlatpickrKeyboardEvent) => handleKeypress(e)}
                aria-label="dateFrom"
                data-testid="dateFromCalendar"
              />
            </div>
            <div className={classes.calendarInput}>
              <Flatpickr
                id="dateTo"
                placeholder="Выберите период"
                options={{
                  dateFormat: 'd-m-Y',
                  locale: Russian,
                }}
                className={classes.calendarWrapper}
                onChange={(val: TFlatpickrValue) =>
                  handleCalendarChange(val, 'to')
                }
                value={dateTo}
                onKeyDown={(e: TFlatpickrKeyboardEvent) => handleKeypress(e)}
                aria-label="dateTo"
              />
            </div>

            <TextField
              id="account"
              label="Номер счета"
              variant="outlined"
              name="account"
              value={getFieldValue('account')}
              onChange={(e) => onFilterValueChange(e)}
              className={classes.searchInput}
              size="small"
              onKeyDown={(e) => handleKeypress(e)}
            />

            <TextField
              id="sn"
              label="Номер транзакции"
              name="sn"
              value={getFieldValue('sn')}
              variant="outlined"
              onChange={(e) => onFilterValueChange(e)}
              className={classes.searchInput}
              size="small"
              onKeyDown={(e) => handleKeypress(e)}
            />

            <FormControl variant="outlined" className={classes.searchInput}>
              <InputLabel
                className={classes.selectLabel}
                id="demo-simple-select-outlined-label-service"
              >
                Сервис
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label-service"
                id="demo-simple-select-outlined-service"
                className={classes.selectProviderOptions}
                value={getFieldValue('idService')}
                name="idService"
                // @ts-ignore
                onChange={(e) => onFilterValueChange(e)}
                label="idService"
              >
                <MenuItem value={''}>
                  <span>Не выбран</span>
                </MenuItem>

                {serviceList.length &&
                  serviceList.map((item) => {
                    if (item.id === propertyParams.idProvider) {
                      return item.services.map((itemOption) => {
                        return (
                          <MenuItem value={itemOption.id} key={itemOption.id}>
                            <span>{itemOption.name}</span>
                          </MenuItem>
                        );
                      });
                    }
                    return null;
                  })}
              </Select>
            </FormControl>
            <FormControl variant="outlined" className={classes.searchInput}>
              <InputLabel
                className={classes.selectLabel}
                id="select-label-status"
              >
                Статус
              </InputLabel>
              <Select
                labelId="select-label-status"
                id="select-status"
                className={classes.selectProviderOptions}
                value={getFieldValue('processingStatuses')}
                name="processingStatuses"
                // @ts-ignore
                onChange={(e) => onFilterValueChange(e)}
                multiple
                renderValue={(data) =>
                  getRenderValueForSelectStatuses(data as number[])
                }
                label="processingStatuses"
              >
                <MenuItem value="all">
                  <Checkbox
                    checked={
                      (getFieldValue('processingStatuses') as any[]).length ===
                      REPORT_PROCESSING_STATUSES.length
                    }
                    color="default"
                  />
                  <ListItemText primary="Выбрать все" />
                </MenuItem>
                <Divider />
                {REPORT_PROCESSING_STATUSES.map((status, key) => {
                  return (
                    <MenuItem value={key} key={status}>
                      <Checkbox
                        checked={(
                          getFieldValue('processingStatuses') as any[]
                        ).includes(key)}
                        color="default"
                      />
                      <ListItemText primary={status} />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </div>

        <div className={classes.searchBlockButtons}>
          <Button
            variant="contained"
            color="primary"
            ref={ButtonElement}
            className={classes.searchSubmit}
            onClick={() => fetchProviderList()}
          >
            Искать
          </Button>
          {EXPORT_REPORT_TYPES.map((item, i) => {
            return (
              <Button
                key={item.label}
                aria-label={item.label}
                variant="contained"
                color="secondary"
                className={classes.saveReport}
                onClick={() => {
                  checkDataAndFileTypeBeforeDownload(item.label);
                }}
                startIcon={
                  <img
                    src={item.image}
                    alt="export"
                    // className={classes.excelIcon}
                  />
                }
              >
                {item.label}
              </Button>
            );
          })}
        </div>
      </div>
    </>
  );
};

Search.propTypes = {
  setOrderBy: PropTypes.func.isRequired,
};

export default Search;
