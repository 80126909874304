import React from 'react';

import { AppDispatch } from 'types/redux';
import { IProviderList } from 'types/provider';
import { TParamsColumns, TExportType } from 'types/params';

import { TSetRequestError } from './snackbar';

export const checkDataAndFileTypeBeforeDownloadHandler =
  (
    type: TExportType,
    providerList: IProviderList,
    setExportType: React.Dispatch<React.SetStateAction<TExportType>>,
    showSettingsModal: React.Dispatch<React.SetStateAction<boolean>>,
    downloadReport: (type: TExportType, columns?: TParamsColumns) => void,
    setRequestError: TSetRequestError
  ) =>
  (dispatch: AppDispatch) => {
    if (providerList.data.length > 0) {
      setExportType(type);
      if (type === 'csv') showSettingsModal(true);
      else downloadReport(type);
    } else {
      dispatch(
        setRequestError('Нет данных для формирования файла выгрузки', true)
      );
    }
  };
