import { makeStyles, createStyles } from '@material-ui/core/styles';

const FaqStyle = makeStyles(() =>
  createStyles({
    card: {
      boxShadow: '0px 5px 10px rgba(0,0,0,.1)!important',
      padding: '20px',
      height: '100%',
      textAlign: 'left',
      '& span:first-child ': {
        margin: '0 0 20px 0',
        textAlign: 'center',
        display: 'block',
        fontWeight: 600,
      },
      '& div:nth-child(2) ': {
        boxShadow: 'none !important',
      },
      '& table': {
        border: 0,
        '& tr:nth-child(2n) td': {
          background: '#f0f0f0',
        },
      },
    },
    flex: {
      display: 'flex',
    },
    link: {
      color: '#222',
    },
    autoHeight: {
      height: 'auto',
    },
    marginTop: {
      marginTop: '15px',
    },
  })
);

export default FaqStyle;
