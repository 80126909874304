export const setToLocalStorage = (name: string, data: any) => {
  window.localStorage.setItem(name, JSON.stringify(data));
};

export const getFromLocalStorage = (name: string) => {
  if (window.localStorage.getItem(name) !== null) {
    return JSON.parse(window.localStorage.getItem(name) as string);
  }

  return null;
};

export const removeItemFromLocalStarage = (key: string) => {
  localStorage.removeItem(key);
};

export const formatDate = (date: Date | number | string = new Date(), type = 1) => {
  let d = new Date(date);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  let year = d.getFullYear();
  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  if (type === 2) return `${[year, month, day].join('')}000000`;
  else if (type === 3) return `${[year, month, day].join('')}235959`;
  return `${[year, month, day].join('')} `;
};

export const printCheckFromImg = (check: string) => {
  const mywindow = window.open('', `PRINT`, 'height=700,width=1300');

  if (mywindow !== null) {
    const image = `data:image/png;base64, ${check}`;
    mywindow.document.write('<html><head>');
    mywindow.document.write('</head><body >');
    mywindow.document.write(`<img src="${image}" width="250px" />`);
    mywindow.document.write('</body></html>');
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    setTimeout(() => {
      mywindow.print();
    }, 1000);
  }
};

export const splitDateString = (date: string) => {
  let idx = 0;
  const splitAt = [4, 2, 2];
  let outputArr: string[] = [];
  splitAt.forEach((value) => {
    outputArr.push(date.slice(idx, idx + value));
    idx += value;
  });

  return `${outputArr[2]}.${outputArr[1]}.${outputArr[0]}`;
};

export function formatMoney(x: number | string, scope: number | '' = 0) {
  const p = /(-?\d+)(\d{3})/;

  let coins = '00';
  const list = x.toString().replace(',', '.').split('.');
  let y = list[0];

  if (y.length === 0) {
    y = '0';
  }
  while (p.test(y)) {
    y = y.replace(p, '$1 $2');
  }
  if (y === 'NaN') {
    y = '0';
  }
  if (list.length === 1) {
    return y;
  }
  if (scope > 0 && scope) {
    coins = list[1];

    return `${y}.${coins.slice(0, scope)}`;
  }

  return y;
}

export const getClearPhone = (phone: string) => {
  return String(phone).replace(/ /g, '').replace('8(', '').replace(')', '');
};

export const sortData = (data: any[], field: string) => {
  data.sort((a, b) => {
    return a[field] - b[field];
  });
  return data;
};

export const isSuccessStatusCode = (statusCode: number) =>
  statusCode >= 200 && statusCode < 300;

export const getProviderListRequestNumberValue = (param?: string | number) => param || null;
