import { makeStyles, createStyles } from '@material-ui/core/styles';

const HeaderStyle = makeStyles((theme) =>
  createStyles({
    headerMenu: {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    headerWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0px 40px',
      [theme.breakpoints.down('xs')]: {
        padding: '0 0 0 10px',
        justifyContent: 'flex-start',
      },
    },
    chipWrapper: {
      margin: '0 15px 0 auto',
    },
    burgerButton: {
      display: 'none',
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
    },
    menuWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      alignItems: 'center',

      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    userProfileInfo: {
      lineHeight: 1.2,
      textAlign: 'right',
    },
    providerCheckIcon: {
      position: 'absolute',
      left: '0',
      margin: '2px 0 0 8px',
      [theme.breakpoints.down('xs')]: {
        margin: '2px 0 0 18px',
      },
    },
    menuProfilesList: {
      padding: '10px 0',
      width: 270,
      '& .MuiListItem-gutters': {
        paddingLeft: '25px',
        paddingRight: '25px',
        whiteSpace: 'unset',
        lineHeight: 1,
      },
    },
    menuContent: {
      padding: '10px 20px',
    },
    profileSettings: {
      margin: '10px 0 0 0',
    },
    profileProvider: {
      textTransform: 'uppercase',
      color: '#848484',
      maxWidth: 200,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    userLogin: {
      textTransform: 'capitalize',
    },
    mobileDrawerContent: {
      '& .MuiListItem-gutters': {
        paddingLeft: '72px',
      },
    },
    menuIcon: {
      color: '#000',
    },
    headerLink: {
      textDecoration: 'none',
      color: '#000',

      [theme.breakpoints.down('xs')]: {
        color: 'rgba(0, 0, 0, 0.87)',
      },
    },
    headMainMenuItem: {
      color: '#423a3a',
      whiteSpace: 'nowrap',
      textDecoration: 'none',
      padding: '10px',
      marginRight: '10px',
      lineHeight: 1,
      '&:hover': {
        background: '#f0f0f0',
        borderRadius: '5px',
      },
    },

    headMainMenuItemActive: {
      background: '#f0f0f0',
      borderRadius: '5px',
    },
    logoWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    navBar: {
      width: '100%',
      textAlign: 'left',
    },
    headerLogo: {
      width: '120px',
      height: '40px',
      marginRight: '40px',
    },
  })
);

export default HeaderStyle;
